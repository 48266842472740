import React from "react";
import DrawnSignature from "./DrawnSignature";
import TypedSignature from "./TypedSignature";
import { Button, ButtonGroup, Stack } from "@mui/material";

export default function SignaturePad({ setSignatureImage, signatureImage }) {
  const [viewMode, setViewMode] = React.useState("draw"); // [" draw", "view"

  const handleSaveSignatureToEntity = () => {
    // Save signature to entity
    console.log("Signature saved to entity");
  };

  return (
    <Stack direction="column" spacing={2}>
      <div className="flex justify-start items-center gap-3">
        <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
          <Button
            variant={viewMode === "draw" ? "contained" : "outlined"}
            onClick={() => {
              setViewMode("draw");
              setSignatureImage("");
            }}
            sx={{ borderRadius: "3px" }}
          >
            Draw
          </Button>
          <Button
            variant={viewMode === "type" ? "contained" : "outlined"}
            onClick={() => {
              setViewMode("type");
              setSignatureImage("");
            }}
            sx={{ borderRadius: "3px" }}
          >
            Type
          </Button>
        </ButtonGroup>
      </div>
      {viewMode === "draw" ? (
        <DrawnSignature setSignatureImage={setSignatureImage} signatureImage={signatureImage} viewMode={viewMode} />
      ) : (
        <TypedSignature setSignatureImage={setSignatureImage} signatureImage={signatureImage} viewMode={viewMode} />
      )}
    </Stack>
  );
}
