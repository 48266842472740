import { useEffect, useState } from "react";

import { HelpPopover } from "../components/HelpPopover";
// import { useAuth } from "../contexts/useAuth";;
import { useAuth } from "../contexts/useAuth";

import { useParams } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  FormControl,
  TextField,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
  IconButton,
} from "@mui/material";
import * as Yup from "yup";
import supabase from "../config/supabaseClient";
import { useFormik } from "formik";
import legalNameEndings from "../data/data_legal_endings.json";
import { Delete, Description, ExpandMore } from "@mui/icons-material";
// import { v4 as uuidv4 } from "uuid";
// import useSelectDataByEntity from "../hooks/useSelectDataByEntity";

import { handleDeleteFile } from "../utilities/sbtools";

// Yup Validation Schema
const schemaEntity = Yup.object().shape({
  name: Yup.string().when("is_numbered", {
    is: false,
    then: (schema) => schema.required("Required"),
    otherwise: (schema) => schema.nullable(),
  }),
  file_number: Yup.string().nullable(),
  status: Yup.string(),
  is_numbered: Yup.boolean(),
  legal_element_ending: Yup.string(),
  naics_code: Yup.string().nullable(),
  naics_description: Yup.string().nullable(),
  english_name: Yup.string().nullable(),
  eng_nuans_ref_number: Yup.string().nullable(),
  eng_nuans_report_date: Yup.date().nullable(),
  min_num_directors: Yup.number(),
  max_num_directors: Yup.number(),
  req_inc_date: Yup.date().nullable(),
  official_business_email: Yup.string().email("Invalid email address"),
  is_req_nuans_report: Yup.boolean(),
  last_step: Yup.number(),
  ra_is_standard_address: Yup.boolean().nullable(),
  ra_lot_address: Yup.string(),
  ra_street_address: Yup.string().nullable(),
  ra_street_name: Yup.string().nullable(),
  ra_unit: Yup.string().nullable(),
  ra_city: Yup.string().nullable(),
  ra_country: Yup.string().nullable(),
  ra_jurisdiction: Yup.string().nullable(),
  ra_postal_code: Yup.string().nullable(),
});

let nextStep = 0;

// =========================================================
// Update Entity w/ activeStep
// =========================================================
async function updateEntity(eid, entityData, step) {
  entityData.last_step = step;
  console.log("from update function eid: ", eid);
  console.log("from update function updated step: ", entityData.last_step);
  console.log("from update function entityData: ", entityData);

  // if name was set and not null, set the entitydata.english_name to the name
  // entityData is an object

  if (entityData.name) {
    entityData.english_name = entityData.name;
  }

  if (entityData.status === "New") {
    entityData.status = "In-Progress";
  }

  // Update the entity in the supabase with the new data from this step
  const { data, error } = await supabase.from("entity").update(entityData).eq("id", eid).select().single();

  if (error) {
    console.log("error", error);
  }

  if (data) {
    console.log("updateEntity Function Data result: ", data);
    return data;
  }
}

export default function Step1({ activeStep, setActiveStep, handleNext, handleBack }) {
  let { eid } = useParams();
  const { user } = useAuth();

  const [entityFiles, setEntityFiles] = useState([]);
  const [missingNuansReport, setMissingNuansReport] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: "",
      english_name: "",
      legal_element_ending: "Ltd.",
      ra_jurisdiction: "ON",
      ra_street_address: "",
      ra_street_name: "",
      ra_is_standard_address: true,
      ra_city: "",
      ra_postal_code: "",
      inc_is_sole_shareholder: true,
    },

    validationSchema: schemaEntity,

    onSubmit: async (values) => {
      console.log("onSubmit values: ", values);
      handleClickStep1();
    },
  });

  // if (entityFiles.length > 0) {
  //   setMissingNuansReport(false);
  // }

  const handleClickStep1 = async (event) => {
    // event.preventDefault();
    console.log("step1 next clicked");

    if (formik.values.has_nuans_report === true && entityFiles.length === 0) {
      setMissingNuansReport(true);
      // alert("Please upload the NUANS report");
      return;
    }

    if (formik.values.legal_element_ending === null) {
      formik.values.legal_element_ending = "Inc.";
    }

    // FIXME: validate step before this

    // if type is numbered, set req_nuans_report to false
    if (formik.values.is_numbered) {
      formik.values.is_req_nuans_report = false;
    }

    if (formik.values.is_req_nuans_report) {
      nextStep = 100;
      console.log("needs a nuans report, set step10: ", nextStep);
    } else {
      nextStep = 1;
      console.log("does not need a nuans report, set step1: ", nextStep);
    }

    // Call the updateEntity function to update the entity in supabase
    updateEntity(eid, formik.values, nextStep).then((data) => {
      console.log("Step1 returned data", data);
      // setEntityData(data);
      // formik.setValues(data); // set the formik values to the updated data 2024/03/03
      setActiveStep(data.last_step);
    });
  };

  async function uploadNuansReport(e) {
    let file = e.target.files[0];
    console.log("file: ", file);

    // upload the file to the entity_storage_bucket
    try {
      const { data, error } = await supabase.storage.from("entity_storage_bucket").upload(user.id + "/" + file.name, file, { upsert: true });

      if (error) {
        console.log("error", error);
        return;
      }

      if (data) {
        console.log("data", data);
        // create a entity_file record with type: nuans_report

        try {
          const { data: entity_file_data, error: entity_file_error } = await supabase
            .from("entity_file")
            .insert([{ linked_entity: eid, type: "nuans_report_uploaded", storage_path_sb: data.path }]);

          if (entity_file_error) {
            console.log("entity_file_error", entity_file_error);
          }

          if (entity_file_data) {
            console.log("entity_file_data", entity_file_data);
          }
        } catch (error) {
          console.log("entity_file error", error);
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  // get the entity_files by entity_id
  const getEntityFiles = async (entity_id) => {
    try {
      const { data: entity_files_data, error: entity_files_error } = await supabase
        .from("entity_file")
        .select("*")
        .eq("linked_entity", entity_id)
        .select();

      if (entity_files_error) {
        console.log("entity_files_error", entity_files_error);
      }

      if (entity_files_data) {
        console.log("entity_files_data", entity_files_data);
        setEntityFiles(entity_files_data);
      }
    } catch (error) {
      console.log("entity_file catch error", error);
    }
  };

  // Get Entity Data - only runs once as its called in useEffect
  const getEntityData = async () => {
    const { data, error } = await supabase.from("entity").select("*").eq("id", eid).select();

    if (error) {
      console.log(error);
      return;
    }

    if (data.length > 0) {
      console.log("getEntityData data", data);
      formik.setValues(data[0]);
      // setActiveStep(data.last_step);
      console.log("finished setting formik values", formik.values);
      getEntityFiles(data[0].id);
    }
  };

  useEffect(() => {
    // Get Entity Data
    getEntityData();

    // listen for changes to the entity_file table INSERT and DELETE and refresh the entity_files state
    const entityFilesSubscription = supabase
      .channel("entity_file")
      .on("postgres_changes", { event: "INSERT", schema: "public", table: "entity_file" }, (payload) => {
        console.log("entity_file subscription payload", payload);
        console.log(payload.new.linked_entity);
        getEntityFiles(eid);
      })
      .on("postgres_changes", { event: "DELETE", schema: "public", table: "entity_file" }, (payload) => {
        console.log("entity_file subscription payload", payload);
        getEntityFiles(eid);
      })
      .subscribe();

    return () => {
      supabase.removeChannel(entityFilesSubscription);
    };
  }, [eid]);

  // useEffect(() => {
  //   // Get Entity Data
  // }, [eid]);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Stack direction={{ xs: "column", md: "row" }} spacing={2} alignItems={"start"}>
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography variant="body1">I want to create a</Typography>
            <FormControl>
              <InputLabel id="company-type-input-label">Type</InputLabel>
              <Select
                name="is_numbered"
                // value={formik.values.is_numbered == null ? true : formik.values.is_numbered}
                value={formik.values && formik.values.is_numbered == null ? true : formik.values.is_numbered}
                error={formik.touched.is_numbered && Boolean(formik.errors.is_numbered)}
                label="Type"
                onChange={formik.handleChange}
                disabled={true}
              >
                <MenuItem value={true}>Numbered</MenuItem>
                <MenuItem value={false}>Named</MenuItem>
              </Select>
            </FormControl>
            <Typography variant="body1">corporation</Typography>
          </Stack>
          {/* <Stack direction="row" display={"flex"} spacing={2} alignItems={"center"} bgcolor={"gray"}>
            <Typography variant="body1">corporation</Typography>
          </Stack> */}
        </Stack>
        <Stack direction={{ xs: "column", md: "row" }} spacing={2} justifyContent="start" alignItems={{ xs: "start", md: "center" }} marginTop={5}>
          {formik.values.is_numbered === true ? (
            <Typography variant="body1"> with a legal ending of</Typography>
          ) : (
            <Typography variant="body1"> with a legal name of</Typography>
          )}
          {/* conditionally render this */}
          {formik.values.is_numbered === false && (
            <>
              <FormControl>
                <TextField
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                  label="Name"
                />
              </FormControl>
              <Typography>with a legal ending of</Typography>
            </>
          )}
          {/* end of conditional rendering */}
          <Stack direction="row" spacing={2} alignItems="center">
            <FormControl>
              <InputLabel id="legal-element-input-label">Legal Ending</InputLabel>
              <Select
                name="legal_element_ending"
                value={formik.values.legal_element_ending === null ? "Inc" : formik.values.legal_element_ending}
                label="Legal Ending"
                onChange={formik.handleChange}
                error={formik.touched.legal_element_ending && Boolean(formik.errors.legal_element_ending)}
                sx={{ minWidth: 120 }}
              >
                {legalNameEndings.map((ending) => (
                  <MenuItem key={ending.id} value={ending.name}>
                    {ending.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <HelpPopover>
              <Stack spacing={1} p={3} maxWidth={300}>
                <Typography sx={{ p: 1 }}>A legal suffix is required for both Numbered and Named corporations.</Typography>
              </Stack>
            </HelpPopover>
          </Stack>
        </Stack>
        {formik.values.is_numbered === false && (
          <Stack direction="row" spacing={2} mt={5} justifyContent="center">
            {/* <Card
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minWidth: "250px",
                minHeight: "80px",
                padding: 2,

                borderRadius: "5px",
                background: "linear-gradient(to right, #8E8C8D, #B8B5B6)",
              }}
            > */}
            <Card
              elevation={0}
              sx={{
                padding: 2,
                paddingX: 5,
                bgcolor: "primary.light",
              }}
            >
              <Typography fontSize={"14px"} color={"white"} textAlign={"center"} fontFamily={"monospace"}>
                {formik?.values.name.toUpperCase()}{" "}
                {formik?.values.legal_element_ending === null ? "Inc." : formik?.values.legal_element_ending.toUpperCase()}
              </Typography>
            </Card>
          </Stack>
        )}
        <Stack direction="row" spacing={2} mt={5} justifyContent="flex-start" display={formik.values.is_numbered === true ? "none" : "block"}>
          {/* <FormControl>
            <FormControlLabel
              htmlFor="nuans-report-checkbox"
              control={
                <Checkbox
                  name="is_req_nuans_report"
                  id="nuans-report-checkbox"
                  value={formik.values.is_req_nuans_report}
                  checked={formik.values.is_req_nuans_report === true ? true : false}
                  onChange={formik.handleChange}
                />
              }
              label="I need a NUANS report"
            />
          </FormControl>
          <HelpPopover>
            <Stack spacing={2} p={5} maxWidth={300}>
              <Typography variant="h6">Some type in the popover</Typography>
              <Divider />
              <Typography variant="body1">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero.</Typography>
            </Stack>
          </HelpPopover> */}

          <Stack direction={"column"} gap={3}>
            {formik.values.has_nuans_report ? (
              <>
                <Typography variant="subtitle1">
                  You specified that you have a NUANs name reservation. Please upload the name reservation report
                </Typography>
                {!entityFiles || entityFiles.length === 0 ? (
                  <Box>
                    <input type="file" name="nuans_report" id="nuans_report" onChange={(e) => (uploadNuansReport(e), getEntityData())} />
                    {missingNuansReport ? (
                      <Box pt={3}>
                        <Typography variant="subtitle2" color={"red"}>
                          Please upload the NUANS report
                        </Typography>
                      </Box>
                    ) : null}
                  </Box>
                ) : null}
              </>
            ) : null}
          </Stack>
          <Stack direction={"column"} gap={3}>
            {entityFiles && entityFiles.length > 0
              ? entityFiles.map((file) => (
                  <Card key={file.id} sx={{ padding: 2, marginTop: 3 }} elevation={1}>
                    {/* <Typography variant="body1">{file.type}</Typography> */}
                    <Stack direction={"row"} spacing={2} justifyContent={"space-between"}>
                      <Stack direction="row" spacing={2} display={"flex"} justifyContent={"start"} alignItems={"center"}>
                        <Description color="primary" />
                        <Typography variant="body1">{file.storage_path_sb.split("/")[1]}</Typography>
                      </Stack>
                      {/* <Button variant="contained" color="primary" onClick={() => handleDownloadFile("entity_storage_bucket", file.storage_path_sb)}>
                      Download
                    </Button> */}
                      {/* <Button
                        fullWidth={false}
                        variant="contained"
                        color="error"
                        onClick={() => handleDeleteFile("entity_storage_bucket", file.storage_path_sb, file.id)}
                        >
                        Delete
                        </Button> */}
                      <IconButton aria-label="delete" onClick={() => handleDeleteFile("entity_storage_bucket", file.storage_path_sb, file.id)}>
                        <Delete color="icon.main" />
                      </IconButton>
                    </Stack>
                  </Card>
                ))
              : null}
          </Stack>
          <Stack direction={"row"}></Stack>
        </Stack>
        <Stack direction="row" spacing={2} justifyContent={"end"} mt={3}>
          {/* <Button variant="contained" color="primary" onClick={handleClickStep1}> */}
          <Button variant="contained" color="primary" onClick={formik.handleSubmit}>
            Next
          </Button>
        </Stack>
      </form>
      {/* <Box marginTop={"50px"}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant="subtitle1">Debug</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <pre>{JSON.stringify(formik.errors, null, 2)}</pre>
            <pre>{JSON.stringify(formik.values, null, 2)}</pre>
            <pre>{JSON.stringify(user, null, 2)}</pre>
          </AccordionDetails>
        </Accordion>
      </Box> */}
    </>
  );
}
