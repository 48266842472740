import { AutoAwesome } from "@mui/icons-material";
import { Box, Button, Card, Stack, Typography } from "@mui/material";
import React from "react";

export default function MagicLinkLoginCard() {
  const searchParams = new URLSearchParams(window.location.search);
  const link = searchParams.get("link");
  const type = searchParams.get("type");

  if (!link) {
    return (
      <Stack alignItems="center" justifyContent="center" height="80vh" bgcolor="card.main">
        <Card sx={{ p: 2, width: 300 }} variant="outlined">
          <Stack direction="column" alignItems="center" spacing={2}>
            <Typography variant="h7">No link found</Typography>

            <Button sx={{ width: "100%" }} variant="contained" onClick={() => (window.location.href = "/login")}>
              Return to Login
            </Button>
          </Stack>
        </Card>
      </Stack>
    );
  }

  return (
    <Stack alignItems="center" justifyContent="center" height="80vh" bgcolor="card.main">
      <Card sx={{ p: 2, width: 300 }} variant="outlined">
        <Stack direction="column" alignItems="center" spacing={2}>
          <Typography variant="h5">Magic Link</Typography>

          <Button
            startIcon={<AutoAwesome />}
            sx={{ width: "100%" }}
            variant="contained"
            onClick={() => (window.location.href = link + `&type=${type}`)}
          >
            Login
          </Button>
          <Typography>This is a one-time link to login to your account</Typography>
        </Stack>
      </Card>
      {/* <Typography>{type}</Typography> */}
      {/* <Typography>{link}</Typography> */}
    </Stack>
  );
}
