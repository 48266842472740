import React from "react";
import { Box, Skeleton, Stack } from "@mui/material";

export default function LoadingSkeleton() {
  return (
    <Stack direction="row" flexWrap={"wrap"} spacing={2} p={5}>
      <Box sx={{ width: 320 }}>
        <Skeleton animation="wave" width={170} />
        <Skeleton animation="wave" height={20} width={150} />
        <Skeleton animation="wave" height={20} width={190} />
      </Box>
      <Box sx={{ width: 300 }}>
        <Skeleton animation="wave" width={170} />
        <Skeleton animation="wave" height={20} width={150} />
        <Skeleton animation="wave" height={20} width={190} />
      </Box>
      <Box sx={{ width: 300 }}>
        <Skeleton animation="wave" width={170} />
        <Skeleton animation="wave" height={20} width={150} />
        <Skeleton animation="wave" height={20} width={190} />
      </Box>
    </Stack>
  );
}
