import React, { useEffect, useState } from "react";
import supabase from "../../../config/supabaseClient";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Chip, CircularProgress, Fade, Stack, Typography } from "@mui/material";
import LoadingSkeleton from "../../LoadingSkeleton";
import { Check, CheckCircleOutline } from "@mui/icons-material";
import { getShareLedgerByEntityId } from "./ledgerData";

export default function ShareLedgerTable({ entityId }) {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  function calculateTotalSharesHeld(transactions) {
    const totalSharesAcquired = transactions.reduce((acc, transaction) => {
      if (transaction.transaction_type === "Issuance") {
        return acc + transaction.qty;
      }
      return acc;
    }, 0);

    const totalSharesTransferred = transactions.reduce((acc, transaction) => {
      if (transaction.transaction_type === "Transfer") {
        if (transaction.to_shareholder_id !== transactions[0].to_shareholder_id) {
          return acc + transaction.qty;
        }
        if (transaction.to_shareholder_id === transactions[0].to_shareholder_id) {
          return acc - transaction.qty;
        }
      }
      return acc;
    }, 0);

    return totalSharesAcquired - totalSharesTransferred;
  }

  useEffect(() => {
    console.log("entityId", entityId);
    const getData = async () => {
      setLoading(true);
      const shareLedgerData = await getShareLedgerByEntityId(entityId);
      if (shareLedgerData) setData(shareLedgerData);
      setLoading(false);
    };
    getData();

    //cleanup
    return () => {
      setData({});
    };
  }, [entityId]);

  if (loading)
    return (
      <Fade in={true} timeout={500} transitionDelay={500}>
        <Box sx={{ height: "100px", width: "100%" }}>
          <LoadingSkeleton />
        </Box>
      </Fade>
    );
  return (
    <Fade in={true} timeout={500} transitionDelay={500}>
      <Stack>
        {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
        {Object.entries(data.groupedData || {})
          .sort((a, b) => a[0].localeCompare(b[0]))
          .map(([shareClass, transactions]) => (
            <Stack key={shareClass}>
              {/* Loop through each shareholder */}
              {Object.entries(transactions).map(([shareholder, transactions]) => (
                <Stack key={shareholder} sx={{ border: 1, borderColor: "divider", p: 2, mb: 10 }}>
                  <Stack direction="column" justifyContent="center" alignItems="center">
                    <Typography sx={{ fontSize: "1.5rem", fontWeight: "bold" }}>Share Ledger for {data?.entity?.english_name}</Typography>
                    {/* underline the share class */}
                    <Typography sx={{ fontSize: "1.25rem", fontWeight: "bold" }}>{shareClass}</Typography>
                  </Stack>
                  <Stack direction="column" alignItems="flex-start" mb={2}>
                    <Typography sx={{ fontSize: "1rem", fontWeight: "bold" }}>{shareholder}</Typography>
                    <Typography
                      sx={{ fontSize: "0.8rem" }}
                    >{`${transactions[0].to_shareholder?.unit} ${transactions[0].to_shareholder?.street_address} ${transactions[0].to_shareholder?.street_name}, ${transactions[0].to_shareholder?.city}, ${transactions[0].to_shareholder?.jurisdiction}, ${transactions[0].to_shareholder?.postal_code}`}</Typography>
                  </Stack>
                  {/* add column border */}
                  <TableContainer sx={{ bgcolor: "card.main", border: 1, borderBottom: 0, borderColor: "divider" }}>
                    <Table stickyHeader aria-label="share register table" fullWidth>
                      <TableHead>
                        <TableRow>
                          <TableCell>Date</TableCell>
                          <TableCell>Type</TableCell>
                          <TableCell>Share Class</TableCell>
                          <TableCell>Shareholder</TableCell>
                          <TableCell>Shares Transferred</TableCell>
                          <TableCell>Shares Acquired</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
                        {transactions.map((transaction, index) => (
                          <TableRow key={index}>
                            <TableCell>
                              {new Date(transaction.created_at).toLocaleDateString("en-US", {
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                                hour: "2-digit",
                                minute: "2-digit",
                              })}
                            </TableCell>
                            <TableCell>{transaction.transaction_type}</TableCell>
                            <TableCell>
                              <Chip label={shareClass} variant="filled" sx={{ bgcolor: "lightgray" }} />
                            </TableCell>
                            <TableCell>
                              {transaction.transaction_type === "Issuance" && "From Treasury"}
                              {transaction.transaction_type === "Transfer" &&
                                transaction.to_shareholder_id === transactions[0].to_shareholder_id &&
                                `From: ${transaction.from_shareholder?.first_name} ${transaction.from_shareholder?.last_name}`}
                              {transaction.transaction_type === "Transfer" &&
                                transaction.to_shareholder_id !== transactions[0].to_shareholder_id &&
                                `To: ${transaction.to_shareholder?.first_name} ${transaction.to_shareholder?.last_name}`}
                            </TableCell>
                            <TableCell>
                              {/* Transaction is an issuance */}
                              {transaction.transaction_type === "Issuance" && "-"}
                              {/* Transaction is a transfer and the to shareholder of this transaction is not the same as the to shareholder of the current transaction object - means the shares are being transferred out - transferred to another shareholder */}
                              {transaction.transaction_type === "Transfer" &&
                                transaction.to_shareholder_id !== transactions[0].to_shareholder_id &&
                                transaction.qty}
                              {/* Transaction is a transfer and the to shareholder of this transaction is the same as the to shareholder of the current transaction object - means the shares are being transferred in - acquired */}
                              {transaction.transaction_type === "Transfer" &&
                                transaction.to_shareholder_id === transactions[0].to_shareholder_id &&
                                "-"}
                            </TableCell>
                            <TableCell>
                              {transaction.transaction_type === "Issuance" && transaction.qty}
                              {transaction.transaction_type === "Transfer" &&
                                transaction.to_shareholder_id === transactions[0].to_shareholder_id &&
                                transaction.qty}
                              {transaction.transaction_type === "Transfer" &&
                                transaction.to_shareholder_id !== transactions[0].to_shareholder_id &&
                                "-"}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Stack direction="column" alignItems="flex-start" spacing={5} sx={{ mt: 2 }}>
                    <Typography sx={{ fontSize: "1rem", fontWeight: "bold" }}>Total Shares Held: {calculateTotalSharesHeld(transactions)}</Typography>
                    <Typography sx={{ fontSize: "0.8rem" }}>Generated on {data?.date_generated}</Typography>
                  </Stack>
                </Stack>
              ))}
            </Stack>
          ))}
      </Stack>
    </Fade>
  );
}
