import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Modal,
  Paper,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
  IconButton,
  Alert,
  TextField,
  CircularProgress,
  Grid,
  Divider,
  SpeedDial,
  SpeedDialAction,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import {
  Autorenew,
  CalendarMonth,
  CheckBox,
  CheckBoxOutlineBlankRounded,
  CheckBoxRounded,
  CreditCard,
  Description,
  Email,
  ExpandMore as ExpandMoreIcon,
  Help,
  HelpOutline,
  Lock,
  Phone,
  Verified,
} from "@mui/icons-material";
import React, { useEffect, useMemo } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import supabase from "../config/supabaseClient";
import PdfViewer from "../components/PdfViewer";
import { getFileUrl, getSignedFileUrl } from "../utilities/sbtools";
import { set } from "lodash";
import LoadingSkeleton from "../components/LoadingSkeleton";
import Receipt1 from "../components/Receipt1";
import { Controller, useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import toast from "react-hot-toast";
import PdfViewer2 from "../components/PdfViewer2";
import { useAuth } from "../contexts/useAuth";
import { convertTimeStampToDate, entityStatusColor, entityStatusTranslation, upperCaseAllWords, uppercaseFirstLetter } from "../utilities/tools";

import ShareholdersTab from "../components/entityview/shareholders/ShareHoldersTab";
import DirectorsTable from "../components/entityview/shareholders/directors/DirectorsTable";
import OfficersTable from "../components/entityview/shareholders/directors/OfficersTable";
import ObrNotCompletedMsg from "../components/entityview/ObrNotCompletedMsg";
import HelpRequestPopup from "../components/requests/HelpRequestPopup";

const tabs = ["summary", "shareholders", "directors", "officers", "documents", "sharing", "billing"];

export default function EntityView() {
  const [loading, setLoading] = React.useState(false);
  const { user } = useAuth();

  //  get eid from url
  const { eid } = useParams();

  // get the query param from the url
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  // console.log("tab", tab);

  const [entity, setEntity] = React.useState({});
  const [value, setValue] = React.useState(0);

  const [shareholders, setShareholders] = React.useState([]);
  const [shares, setShares] = React.useState([]);
  const [officers, setOfficers] = React.useState([]);
  const [directors, setDirectors] = React.useState([]);
  const [documents, setDocuments] = React.useState([]);
  const [subscription, setSubscription] = React.useState([]);
  const [transactions, setTransactions] = React.useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const navigate = useNavigate();

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
        {value === index && <Box>{children}</Box>}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  // set the tab value from the url
  useEffect(() => {
    setValue(tab ? tabs.indexOf(tab) : 0);
  }, [tab]);

  useEffect(() => {
    async function getEntity() {
      setLoading(true);
      // get entity from supabase

      try {
        // Fetch the main entity
        const { data: data_entity, error: error_entity } = await supabase.from("entity").select("*").eq("id", eid);
        if (error_entity) {
          console.log("error", error_entity);
          return;
        }

        // console.log("entity_data:", entity);

        // check if the entity object is empty
        if (data_entity.length === 0) {
          console.log("entity is empty");
          setLoading(false);
          return (
            <Alert severity="error">
              <Typography>Entity not found</Typography>
            </Alert>
          );
        }

        // Set the entity state
        setEntity(data_entity[0]);

        // Get the entity's personnel
        // Shareholders
        const { data: data_personnel, error: error_personnel } = await supabase.from("shareholder").select("*").eq("entity_id", eid);
        if (error_personnel) {
          console.log("error", error_personnel);
          return;
        }
        setShareholders(data_personnel);

        const { data: data_shares, error: error_shares } = await supabase
          .from("share")
          .select(`shareholder(id, first_name, last_name) ,share_class(id, name), qty, consideration, id`)
          .eq("entity_id", eid);
        if (error_shares) {
          console.log("error", error_shares);
          return;
        }
        setShares(data_shares);

        // Officers
        const { data: data_officers, error: error_officers } = await supabase.from("officer").select("*").eq("entity_id", eid);
        if (error_officers) {
          console.log("error", error_officers);
          return;
        }
        setOfficers(data_officers);

        // Directors
        const { data: data_directors, error: error_directors } = await supabase.from("director").select("*").eq("entity_id", eid);
        if (error_directors) {
          console.log("error", error_directors);
          return;
        }
        setDirectors(data_directors);

        // Get the entity's documents

        const { data: data_documents, error: error_documents } = await supabase
          .from("entity_file")
          .select("*")
          .eq("linked_entity", eid)
          .eq("is_deleted", false)
          .eq("is_published", true);
        if (error_documents) {
          console.log("error", error_documents);
          return;
        }

        setDocuments(data_documents);

        // Get the entity's subscription

        const { data: data_subscription, error: error_subscription } = await supabase.from("subscription").select("*").eq("entity_id", eid);

        if (error_subscription) {
          console.log("error", error_subscription);
          return;
        }
        setSubscription(data_subscription[0]);

        // Get the entity's sharing
        // *TODO - data model changes required see Linear

        // Get the entity's billing

        const { data: data_transactions, error: error_transactions } = await supabase
          .from("transaction")
          .select("*")
          .eq("entity_id", eid)
          .order("created_at", { ascending: false });
        if (error_transactions) {
          console.log("error", error_transactions);
          return;
        }
        setTransactions(data_transactions);
      } catch (error) {
        console.log("catch-error", error);
      }
      setLoading(false);
    }

    getEntity();
  }, [eid]);

  if (loading) {
    return <LoadingSkeleton />;
  }

  // if entity is empty
  if (Object.keys(entity).length === 0) {
    return (
      <Stack p={1} spacing={2} display={"flex"} alignItems={"center"} justifyContent={"center"}>
        <Card variant="outlined" sx={{ minWidth: "300px", bgcolor: "card.main" }} p={3}>
          <Stack direction="column" spacing={2} display={"flex"} justifyContent={"center"} alignItems={"center"}>
            <img src="/illustration_question.svg" width={250} />
          </Stack>
          <Alert severity="info">
            <Typography>Entity not found</Typography>
          </Alert>
        </Card>
      </Stack>
    );
  } else {
    return (
      <>
        {loading ? (
          <LoadingSkeleton />
        ) : (
          <Stack p={2} spacing={2}>
            {(entity.status === "In-Progress" || entity.status === "New") && (
              <Alert
                severity="warning"
                action={
                  <Button sx={{ my: 0 }} variant="contained" size="medium" onClick={() => navigate(`/setup/entity/${entity.id}`)}>
                    Continue
                  </Button>
                }
                sx={{ py: 2 }}
              >
                <Stack display={"flex"} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"} spacing={2}>
                  <Typography>Click continue to finish setting up this company</Typography>
                </Stack>
              </Alert>
            )}
            {entity.status === "processing" && (
              <Alert severity="success">
                <Stack display={"flex"} flexDirection={"row"} justifyContent={"space-between"} spacing={2}>
                  <Typography>Your company setup is being processed by our team, we will notify you by email once its ready</Typography>
                </Stack>
              </Alert>
            )}
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 3 }}
              display={"flex"}
              justifyContent={"space-between"}
              flexWrap={"wrap"}
              px={0}
            >
              <Typography color={"black"} sx={{ fontSize: { xs: "1.25rem", sm: "1.5rem" } }}>
                {/* {entity?.name && !entity?.is_numbered ? upperCaseAllWords(entity?.name) : "### Ontario, Inc."} */}
                {/* Todo: add consistent logic */}
                {/* {!entity?.name && !entity?.is_numbered && "Name Pending"}
                {entity?.name && !entity?.is_numbered && uppercaseFirstLetter(entity?.name) + " " + entity?.legal_element_ending}
                {!entity?.name && entity?.is_numbered && "### Ontario Inc."} {entity?.name && entity?.is_numbered && upperCaseAllWords(entity?.name)} */}
                {entity?.english_name}
              </Typography>
              <Stack direction={"row"} spacing={2} display={"flex"} justifyContent={"end"}>
                {/* <Chip label={entity.status.charAt(0).toUpperCase() + entity.status.slice(1)} color={"success"} /> */}
                <Chip
                  // label={entity.status.charAt(0).toUpperCase() + entity.status.slice(1)}
                  label={entityStatusTranslation(entity?.status).label}
                  sx={{
                    color: "white",
                    bgcolor: entityStatusTranslation(entity?.status).color,
                  }}
                />
              </Stack>
            </Stack>
            {/* <Box sx={{ borderBottom: 0, borderColor: "divider" }}> */}
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              // scrollButtons
              // allowScrollButtonsMobile
              aria-label="Company tab section"
              // sx={{ bgcolor: "card.main" }}
            >
              <Tab
                label="Summary"
                {...a11yProps(0)}
                sx={{ bgcolor: value === 0 ? "tab.selected" : "transparent" }}
                onClick={() => {
                  const newUrl = `/view/${eid}?tab=summary`;
                  window.history.pushState(null, "", newUrl);
                }}
              />
              <Tab
                label="Shareholders"
                {...a11yProps(1)}
                sx={{ bgcolor: value === 1 ? "tab.selected" : "transparent" }}
                onClick={() => {
                  const newUrl = `/view/${eid}?tab=shareholders`;
                  window.history.pushState(null, "", newUrl);
                }}
              />
              <Tab
                label="Directors"
                {...a11yProps(2)}
                sx={{ bgcolor: value === 2 ? "tab.selected" : "transparent" }}
                onClick={() => {
                  const newUrl = `/view/${eid}?tab=directors`;
                  window.history.pushState(null, "", newUrl);
                }}
              />
              <Tab
                label="Officers"
                {...a11yProps(3)}
                sx={{ bgcolor: value === 3 ? "tab.selected" : "transparent" }}
                onClick={() => {
                  const newUrl = `/view/${eid}?tab=officers`;
                  window.history.pushState(null, "", newUrl);
                }}
              />
              <Tab
                label="Documents"
                {...a11yProps(4)}
                sx={{ bgcolor: value === 4 ? "tab.selected" : "transparent" }}
                onClick={() => {
                  const newUrl = `/view/${eid}?tab=documents`;
                  window.history.pushState(null, "", newUrl);
                }}
              />
              <Tab
                label="Sharing"
                {...a11yProps(5)}
                sx={{ bgcolor: value === 5 ? "tab.selected" : "transparent" }}
                onClick={() => {
                  const newUrl = `/view/${eid}?tab=sharing`;
                  window.history.pushState(null, "", newUrl);
                }}
              />
              <Tab
                label="Billing"
                {...a11yProps(6)}
                sx={{ bgcolor: value === 6 ? "tab.selected" : "transparent" }}
                onClick={() => {
                  const newUrl = `/view/${eid}?tab=billing`;
                  window.history.pushState(null, "", newUrl);
                }}
              />
            </Tabs>
            {/* </Box> */}
            <CustomTabPanel value={value} index={0}>
              {/* 1/2 this box enables the stack within the component to be scrollable */}
              <Box id="panel-box" p={0} sx={{ overflowY: "auto", maxHeight: "calc(100vh - 300px)" }}>
                {entity.obr_completed ? <SummaryTabContents eid={eid} entity={entity} /> : <ObrNotCompletedMsg entity_id={eid} />}
              </Box>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <Box id="panel-box" p={0} sx={{ overflowY: "auto", maxHeight: "calc(100vh - 300px)" }}>
                {/* <PersonelTabContents entity={entity} shareholders={shareholders} officers={officers} directors={directors} shares={shares} /> */}
                {entity.obr_completed ? <ShareholdersTab entityId={entity.id} /> : <ObrNotCompletedMsg entity_id={eid} />}
              </Box>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <Box id="panel-box" p={0} sx={{ overflowY: "auto", maxHeight: "calc(100vh - 300px)" }}>
                {entity.obr_completed ? <DirectorsTable entityId={entity.id} /> : <ObrNotCompletedMsg entity_id={eid} />}
              </Box>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
              <Box id="panel-box" p={0} sx={{ overflowY: "auto", maxHeight: "calc(100vh - 300px)" }}>
                {entity.obr_completed ? <OfficersTable entityId={entity.id} /> : <ObrNotCompletedMsg entity_id={eid} />}
              </Box>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={4}>
              <Box id="panel-box" p={0} sx={{ overflowY: "auto", maxHeight: "calc(100vh - 300px)" }}>
                {entity.obr_completed ? (
                  <CompanyDocumentsTabContents eid={entity.id} documents={documents} />
                ) : (
                  <ObrNotCompletedMsg entity_id={eid} />
                )}
              </Box>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={5}>
              <Box id="panel-box" p={0} sx={{ overflowY: "auto", maxHeight: "calc(100vh - 300px)" }}>
                <SharingTabContents eid={entity.id} />
              </Box>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={6}>
              <Box id="panel-box" p={0} sx={{ overflowY: "auto", maxHeight: "calc(100vh - 300px)" }}>
                <BillingTabContents
                  eid={entity.id}
                  entity_name={entity.name}
                  subscription={subscription}
                  transactions={transactions}
                  entity_status={entity.status}
                  stripe_customer_id={entity.stripe_customer_id}
                  entity_is_paid={entity.is_paid}
                  user={user}
                />
              </Box>
            </CustomTabPanel>
          </Stack>
        )}
        <HelpRequestPopup entityData={entity} />
      </>
    );
  }
}

function SummaryTabContents({ eid, entity, shareholders, officers, directors }) {
  return (
    // 2/2 this stack is internally scrollable
    <Stack id="stack-summary" spacing={2} sx={{ maxWidth: "1000px", overflowY: "hidden", p: 5 }}>
      <Card variant="outlined" sx={{ minWidth: "300px", bgcolor: "card.main" }} p={3}>
        <CardContent>
          <Stack spacing={2}>
            <Stack direction="row" spacing={2} alignItems={"center"}>
              <Typography variant="h6">Date of Incorporation:</Typography>

              {entity.date_incorporated ? (
                <Typography sx={{ fontSize: "1rem" }}>{entity.date_incorporated}</Typography>
              ) : (
                <Chip variant="filled" label="In-Progress" />
              )}
            </Stack>
            <Stack direction="row" spacing={2} alignItems={"center"}>
              <Typography variant="h6">Ontario Corporation #:</Typography>
              {entity.ocn ? <Typography sx={{ fontSize: "1rem" }}>{entity.ocn}</Typography> : <Chip variant="filled" label="In-Progress" />}
            </Stack>
            {/* <pre>{JSON.stringify(entity, null, 2)}</pre> */}
          </Stack>
        </CardContent>
      </Card>
      <Card variant="outlined" sx={{ minWidth: "300px", bgcolor: "card.main" }} p={3}>
        <CardContent>
          <Stack spacing={2}>
            <Typography variant="h6">Registered Address:</Typography>
            <Box flexGrow={3} padding={1} color={"black"}>
              {entity.last_step < 3 ? (
                <Chip variant="filled" label="In-Progress" />
              ) : (
                <>
                  {entity.ra_is_standard_address ? (
                    <Typography>
                      {entity.ra_street_address ? entity.ra_street_address : ""} {entity.ra_street_name ? entity.ra_street_name : ""}{" "}
                      {entity.ra_city ? entity.ra_city : ""}, {entity.ra_jurisdiction ? entity.ra_jurisdiction : ""}{" "}
                      {entity.ra_country ? entity.ra_country : ""} {entity.ra_postal_code ? entity.ra_postal_code : ""}
                    </Typography>
                  ) : (
                    <Typography>
                      {entity.ra_lot_address ? entity.ra_lot_address : ""} {entity.ra_city ? entity.ra_city : ""},{" "}
                      {entity.ra_jurisdiction ? entity.ra_jurisdiction : ""} {entity.ra_country ? entity.ra_country : ""}{" "}
                      {entity.ra_postal_code ? entity.ra_postal_code : ""}
                    </Typography>
                  )}
                </>
              )}
            </Box>
            {/* <pre>{JSON.stringify(entity, null, 2)}</pre> */}
          </Stack>
        </CardContent>
      </Card>
      <Card variant="outlined" sx={{ minWidth: "300px", bgcolor: "card.main" }} p={3}>
        <CardContent>
          <Stack spacing={2}>
            <Stack direction="row" spacing={2}>
              <Typography variant="h6">Key Dates</Typography>
            </Stack>
            <Stack direction="column" spacing={2}>
              {/* <Typography variant="h6">Ontario Corporation #: {entity.ocn}</Typography> */}
              <Typography sx={{ fontSize: "1rem" }}>Fiscal Year End: {entity.fiscal_year_end_month}</Typography>
              <Divider />
              <Typography sx={{ fontSize: "1rem" }}>Last Annual Return: {entity.last_annual_return ? entity.last_annual_return : "--"}</Typography>
              <Typography sx={{ fontSize: "1rem" }}>Next Annual Return: {entity.last_annual_return ? entity.last_annual_return : "--"}</Typography>
            </Stack>
            {/* <pre>{JSON.stringify(entity, null, 2)}</pre> */}
          </Stack>
        </CardContent>
      </Card>
      {/* Testing */}
    </Stack>
  );
}

function PersonelTabContents({ eid, entity, shareholders, officers, directors, shares }) {
  return (
    <Stack id="stack-summary" spacing={3} sx={{ maxWidth: "1000px", overflowY: "hidden", p: 5 }}>
      <Typography variant="h6">Shareholders</Typography>

      <TableContainer component={Paper} sx={{ bgcolor: "card.main" }}>
        <Table sx={{ minWidth: 320 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="right">Share class</TableCell>
              <TableCell align="right"># of shares</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* 0710 - multi-share class support: map over state: shares */}
            {shares.map((share) => (
              <TableRow key={share.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell component="th" scope="row">
                  {share.shareholder.first_name} {share.shareholder.last_name}
                </TableCell>
                <TableCell align="right">{share.share_class.name}</TableCell>
                <TableCell align="right">{share.qty}</TableCell>
              </TableRow>
            ))}
            {/* {shareholders.map((shareholder) => (
              <TableRow key={shareholder.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell component="th" scope="row">
                  {shareholder.first_name} {shareholder.last_name}
                </TableCell>
                <TableCell align="right">{shareholder.share_qty}</TableCell>
              </TableRow>
            ))} */}
          </TableBody>
        </Table>
      </TableContainer>
      <Typography variant="h6">Directors</Typography>
      <TableContainer component={Paper} sx={{ bgcolor: "card.main" }}>
        <Table sx={{ minWidth: 320 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="right">Appointment Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {directors.map((director) => (
              <TableRow key={director.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell component="th" scope="row">
                  {director.first_name} {director.last_name}
                </TableCell>
                <TableCell align="right">{new Date(director.created_at).toLocaleDateString("en-CA")}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Typography variant="h6">Officers</Typography>
      <TableContainer component={Paper} sx={{ bgcolor: "card.main" }}>
        <Table sx={{ minWidth: 320 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="right">Appointment Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {officers.map((officer) => (
              <TableRow key={officer.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell component="th" scope="row">
                  {officer.first_name} {officer.last_name}
                </TableCell>
                <TableCell align="right">{new Date(officer.created_at).toLocaleDateString("en-CA")}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* <Typography variant="h6">Shareholders</Typography> */}
      {/* <pre>{JSON.stringify(shares, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(shareholders, null, 2)}</pre> */}
      {/* <Typography variant="h6">Officers</Typography>
      <pre>{JSON.stringify(officers, null, 2)}</pre>
      <Typography variant="h6">Directors</Typography>
      <pre>{JSON.stringify(directors, null, 2)}</pre> */}
      <Card variant="outlined" sx={{ minWidth: "300px", bgcolor: "white" }} p={3}>
        <CardContent>
          <Stack direction={{ xs: "column", sm: "row" }} spacing={3} display={"flex"} justifyContent={"start"}>
            <img src="/authentication.svg" width={250} />
            <Stack direction="column" spacing={2}>
              <Typography variant="h5" fontWeight={500}>
                Coming soon
              </Typography>
              <Typography>Support for multiple-share classes per company and per shareholder</Typography>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </Stack>
  );
}

function CompanyDocumentsTabContents({ eid, documents }) {
  const [selectedDocumentUrl, setSelectedDocumentUrl] = React.useState("");
  const [documentName, setDocumentName] = React.useState("");
  const [openModal, setOpenModal] = React.useState(false);
  // const [openDocumentModal, setOpenDocumentModal] = React.useState(false);
  // const [closeDocumentModal, setCloseDocumentModal] = React.useState(false);

  const handleOpenDocModal = () => setOpenModal(true);
  const handleCloseDocModal = () => setOpenModal(false);

  async function handleOpenDocument({ documentName, documentUrl }) {
    // const doc = await getFileUrl("entity_storage_bucket", documentUrl);
    const doc = await getSignedFileUrl("entity_storage_bucket", documentUrl);
    console.log("documentName: ", documentName);
    console.log("documentUrl: ", documentUrl);
    console.log("doc: ", doc);
    // setSelectedDocumentUrl("https://pdftron.s3.amazonaws.com/downloads/pl/demo-annotated.pdf");
    setSelectedDocumentUrl(doc);
    setDocumentName(documentName);
    handleOpenDocModal();
  }

  // Create an array of unique folders
  // const uniqueFolders = [...new Set(documents.map((doc) => doc.folder))];

  const uniqueFolders = useMemo(() => {
    const folders = Array.from(new Set(documents?.map((file) => file.folder)));
    // sort the folders alphabetically
    const sortedFolders = folders.sort();

    return sortedFolders;
  }, [documents]);

  return (
    <>
      <Stack id="stack-summary" spacing={2} sx={{ overflowY: "hidden", p: 1 }}>
        {uniqueFolders.map((folder) => (
          <Stack key={folder} spacing={2} sx={{ border: 1, borderColor: "card.border", bgcolor: "card.main", p: 2, borderRadius: 1 }}>
            <Typography variant="h6">{folder}</Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="left">Name</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {documents
                  .filter((doc) => doc.folder === folder)
                  .map((document) => {
                    return (
                      <TableRow key={document.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                        <TableCell component="th" scope="row">
                          {document.friendly_name ? document.friendly_name : document.storage_path_sb.split("/").pop()}
                        </TableCell>
                        <TableCell align="right">
                          <Button
                            variant="contained"
                            size="small"
                            onClick={() =>
                              handleOpenDocument({ documentName: document.storage_path_sb.split("/").pop(), documentUrl: document.storage_path_sb })
                            }
                          >
                            View
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </Stack>
        ))}

        {/* <pre>{JSON.stringify(documents[0], null, 2)}</pre> */}
      </Stack>
      {/* Modal for Apryse Document Viewer */}
      <ViewDocument
        documentName={documentName}
        selectedDocumentUrl={selectedDocumentUrl}
        openModal={openModal}
        handleCloseDocModal={handleCloseDocModal}
      />
    </>
  );
}

function SharingTabContents({ eid }) {
  return (
    <Stack id="stack-summary" spacing={2} sx={{ maxWidth: "1000px", overflowY: "hidden", p: 5 }}>
      <Card variant="outlined" sx={{ minWidth: "300px", bgcolor: "card.main" }} p={3}>
        <CardContent>
          <Stack direction={{ xs: "column", sm: "row" }} spacing={3} display={"flex"} justifyContent={"start"}>
            <img src="/authentication.svg" width={250} />
            <Stack direction="column" spacing={2}>
              <Typography variant="h5" fontWeight={500}>
                Coming soon
              </Typography>
              <Typography>Securely share access to your company with others, such as your lawyer or accountant.</Typography>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </Stack>
  );
}

//? =========================================================================
//? Billing Tab
//? =========================================================================
function BillingTabContents({ eid, entity_name, subscription, transactions, entity_status, stripe_customer_id, entity_is_paid, user }) {
  const [openChangePaymentModal, setOpenChangePaymentModal] = React.useState(false);
  const [checkoutError, setCheckoutError] = React.useState(false);
  const [stripeCheckoutUrl, setStripeCheckoutUrl] = React.useState("");

  const handleOpenChangePayment = () => setOpenChangePaymentModal(true);
  const handleCloseChangePayment = () => setOpenChangePaymentModal(false);

  const [receiptData, setReceiptData] = React.useState({});
  const [openReceiptModal, setOpenReceiptModal] = React.useState(false);
  const handleOpenReceiptModal = () => setOpenReceiptModal(true);
  const handleCloseReceiptModal = () => setOpenReceiptModal(false);

  const [loadingChangePayment, setLoadingChangePayment] = React.useState(false);
  const [openDeleteEntityConfirmation, setOpenDeleteEntityConfirmation] = React.useState(false);

  const navigate = useNavigate();

  //? =========================================================================
  //? Environment Config
  //? =========================================================================
  const environmentConfig = {
    development: {
      stripeServerUrl: import.meta.env.VITE_STRIPE_SERVER_URL_DEV,
    },
    production: {
      stripeServerUrl: import.meta.env.VITE_STRIPE_SERVER_URL_PROD,
    },
  };

  const env = import.meta.env.VITE_APP_MODE;
  const { stripeServerUrl } = environmentConfig[env];

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "400px",
    bgcolor: "background.paper",

    boxShadow: 24,
    p: 4,
  };

  // Add react hook form and yup validation here...
  const { control, handleSubmit, getValues } = useForm({
    defaultValues: {
      cardNumber: "",
      expiryDate: "",
      cvv: "",
    },
  });

  const onSubmit = (data) => {
    setLoadingChangePayment(true);
    // add fake delay to simulate API request
    setTimeout(() => {
      setLoadingChangePayment(false);
    }, 2000);

    // make POST request to API to update payment method
    console.log(data);
  };

  const handleManageSubscription = async () => {
    // const stripe_response = await fetch(`${stripeServerUrl}/stripe/create-checkout-session`, requestOptions);

    // const stripe_response_json = await stripe_response.json();

    // setStripeCheckoutUrl(stripe_response_json.url);
    const payload = {
      stripe_customer_id: stripe_customer_id,
      return_url: window.location.href,
    };

    const requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${import.meta.env.VITE_API_KEY}`,
      },
      body: JSON.stringify(payload),
    };

    const stripe_portal = await fetch(`${stripeServerUrl}/stripe/create-portal-session`, requestOptions);
    const stripe_portal_json = await stripe_portal.json();
    window.open(stripe_portal_json.url, "_self");
  };

  const handleViewReceipt = async (transaction) => {
    // console.log("receiptData: ", receiptData);
    // setReceiptData(receiptData);
    // handleOpenReceiptModal();

    console.log("transaction: ", transaction);

    const payload = {
      checkout_session_id: transaction.stripe_event_json.data.object.id,
    };

    const requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${import.meta.env.VITE_API_KEY}`,
      },
      body: JSON.stringify(payload),
    };

    const stripe_receipt_url = await fetch(`${stripeServerUrl}/stripe/get-receipt`, requestOptions);
    const stripe_receipt_url_json = await stripe_receipt_url.json();
    window.open(stripe_receipt_url_json.receiptUrl, "_blank");
  };

  function showDeleteEntityConfirmation() {
    setOpenDeleteEntityConfirmation(true);
  }

  async function handleDeleteEntity() {
    // delete supabase entity
    try {
      const { data, error } = await supabase.from("entity").delete().eq("id", eid);
      toast.success("Entity deleted successfully");
      navigate("/dashboard");
      console.log("data delete:", data);
      console.log("error delete:", error);
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  }

  //? Handle Upgrade Subscription
  const handleUpgradeSubscription = async () => {
    const payload = {
      entity_id: `${eid}`,
      email: user.email,
      cancel_path: `${window.location.href}`,
    };

    console.log("payload", payload);
    console.log("stripeServerUrl", stripeServerUrl);

    const requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${import.meta.env.VITE_API_KEY}`,
      },
      body: JSON.stringify(payload),
    };

    const checkout_response = await fetch(`${stripeServerUrl}/stripe/create-checkout-session`, requestOptions);

    const checkout_response_json = await checkout_response.json();

    console.log("checkout_response_json", checkout_response_json.url);

    setStripeCheckoutUrl(checkout_response_json.url);

    //redirect the user to the stripe checkout page
    if (checkout_response_json.url) {
      window.location.href = checkout_response_json.url;
    }
  };

  const annual_maintenance_features = [
    "Annual Return Preparation and Filing",
    "Annual Shareholder Resolutions (standard form)",
    "Annual Director Resolutions (standard form)",
    "Corporate Register Updates",
    "Newsletter / Corporate Law Updates",
    "Exclusive access to online events/webinars",
    "Share Access to Online Minute Book (Coming Soon)",
  ];

  return (
    <>
      <Stack id="stack-summary" spacing={2} sx={{ maxWidth: "1000px", overflowY: "hidden", p: 5 }}>
        <Card variant="outlined" sx={{ minWidth: "300px", bgcolor: "card.main" }} p={3}>
          <CardContent>
            <Stack direction={{ xs: "column", sm: "row" }} spacing={4} display={"flex"} justifyContent={"space-between"} alignItems={"start"}>
              {/* <Stack direction="column" spacing={2}> */}
              <Typography variant="h5" fontWeight={500}>
                Standard Incorporation Package
              </Typography>
              <Box>{entity_is_paid ? <Chip label="Active" color="success" /> : <Chip label="Not Active" color="error" />}</Box>
              {/* </Stack> */}
            </Stack>
          </CardContent>
        </Card>
        {/* If the user has a subscription and it is active, show the annual maintenance subscription card */}
        {subscription?.id && subscription?.is_active === true ? (
          <Card variant="outlined" sx={{ minWidth: "300px", bgcolor: "card.main" }} p={3}>
            <CardContent>
              <Stack spacing={2}>
                <Stack direction={"row"} spacing={2} justifyContent={"space-between"} alignItems={"start"}>
                  <Typography variant="h6">Skylaunch Membership</Typography>
                  <Box>
                    <Chip label={uppercaseFirstLetter(subscription.stripe_subscription_details_json.status)} color="success" />
                  </Box>
                </Stack>

                <Stack spacing={2}>
                  <Stack direction="row" spacing={2} alignItems={"center"}>
                    <CalendarMonth />
                    <Typography>Start Date: {convertTimeStampToDate(subscription.stripe_subscription_details_json.current_period_start)}</Typography>
                  </Stack>

                  {subscription.stripe_subscription_details_json.cancel_at_period_end === false && (
                    <Stack direction="row" spacing={2} alignItems={"center"}>
                      <CalendarMonth />
                      <Typography>
                        Renewal Date: {convertTimeStampToDate(subscription.stripe_subscription_details_json.current_period_end)}
                      </Typography>
                    </Stack>
                  )}
                  {subscription.stripe_subscription_details_json.cancel_at_period_end === true && (
                    <Typography>
                      This subscription was cancelled and will not renew automatically. You can continue to use the service until{" "}
                      {convertTimeStampToDate(subscription.stripe_subscription_details_json.current_period_end)}
                    </Typography>
                  )}
                </Stack>
                {/* <Stack direction="row" spacing={2} alignItems={"center"}>
                  <Autorenew />
                  <Typography>Next Payment: </Typography>
                </Stack> */}
                {/* <pre>{JSON.stringify(subscription, null, 2)}</pre> */}
                <Stack direction="row" spacing={2} display={"flex"} justifyContent={"end"}>
                  <Button variant="contained" color="primary" onClick={handleManageSubscription}>
                    {subscription.stripe_subscription_details_json.cancel_at_period_end === true ||
                    subscription.stripe_subscription_details_json.status === "canceled"
                      ? "Renew Subscription"
                      : "Manage Subscription"}
                  </Button>
                </Stack>
                {/* <pre>{JSON.stringify(subscription, null, 2)}</pre> */}
              </Stack>
            </CardContent>
          </Card>
        ) : (
          <Card variant="outlined" sx={{ minWidth: "300px", bgcolor: "card.main" }} p={3}>
            <CardContent>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={4} display={"flex"} justifyContent={"start"} alignItems={"start"}>
                <img src="/skylaunch_membership.svg" width={250} />
                <Stack direction="column" spacing={2}>
                  <Typography variant="h5" fontWeight={500}>
                    Skylaunch Membership
                  </Typography>
                  <Typography>Our annual subscription includes:</Typography>
                  {annual_maintenance_features.map((feature, index) => {
                    return (
                      <Stack key={index} direction="row" spacing={2} display={"flex"} justifyContent={"start"}>
                        <Verified color="primary" />
                        <Typography>{feature}</Typography>
                      </Stack>
                    );
                  })}

                  {transactions.length !== 0 ? (
                    <Stack direction="row" spacing={2} paddingY={3} display={"flex"} justifyContent={"space-between"}>
                      <Button variant="contained" color="primary" onClick={handleUpgradeSubscription}>
                        Upgrade
                      </Button>
                    </Stack>
                  ) : null}
                </Stack>
              </Stack>
            </CardContent>
          </Card>
        )}
        <Card variant="outlined" sx={{ minWidth: "300px", bgcolor: "card.main", display: transactions.length === 0 ? "none" : "flex" }} p={3}>
          <CardContent>
            {/* {transactions.length === 0 ? <Typography variant="h6">No transactions</Typography> : <Typography variant="h6">Receipts</Typography>} */}
            <Typography variant="h6">Receipts</Typography>
            <Stack direction="column" spacing={2} py={2}>
              {/* <Box>
                <Button variant="contained" color="primary" onClick={handleManageSubscription}>
                  Download Receipts
                </Button>
              </Box> */}
              {transactions.map((transaction) => {
                return (
                  <Stack key={transaction.id} direction="row" spacing={2} display={"flex"} justifyContent={"space-between"}>
                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} spacing={2}>
                      <Description color="primary" />
                      <Typography>{new Date(transaction.created_at).toLocaleDateString("en-CA")}</Typography>
                      <Typography>{uppercaseFirstLetter(transaction.stripe_event_json.data.object.payment_status)}</Typography>
                      <Typography>
                        {(transaction.stripe_event_json.data.object.amount_total / 100).toLocaleString("en-US", {
                          style: "currency",
                          currency: "CAD",
                        })}
                      </Typography>
                    </Stack>
                    {/* <Typography>{transaction.brand ? transaction.brand : "----"}</Typography> */}
                    {/* <Typography fontFamily={"monospace"}>{transaction.last4 ? transaction.last4 : "----------------"}</Typography> */}
                    <Button variant="contained" size="small" onClick={() => (handleViewReceipt(transaction), console.log(transaction))}>
                      View
                    </Button>
                  </Stack>
                );
              })}
            </Stack>
            {/* <pre>{JSON.stringify(transactions, null, 2)}</pre> */}
            {/* <pre>{stripeServerUrl}</pre> */}
            {/* <pre>SCID: {stripe_customer_id}</pre> */}

            {/* <pre>Sub: {JSON.stringify(subscription, null, 2)}</pre> */}
          </CardContent>
        </Card>
        {/* <pre>{JSON.stringify(entity_status, null, 2)}</pre> */}
        {entity_status !== "In-Progress" && entity_status !== "processing" && (
          <Stack direction="row" spacing={2} display={"flex"} justifyContent={"space-between"}>
            <Button variant="contained" color="error" onClick={showDeleteEntityConfirmation}>
              Delete Company
            </Button>
          </Stack>
        )}
      </Stack>
      {/* Delete confirmation dialog box */}
      <Dialog open={openDeleteEntityConfirmation} onClose={setOpenDeleteEntityConfirmation}>
        <DialogTitle id="alert-dialog-title">{"Delete Company"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this company? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => setOpenDeleteEntityConfirmation(false)} color="primary" autoFocus>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleDeleteEntity} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Modal open={openChangePaymentModal} onClose={handleCloseChangePayment}>
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Change Payment Method
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2, py: 2 }}>
            Please enter your new payment method details
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={2}>
              <Controller name="cardNumber" control={control} render={({ field }) => <TextField {...field} label="Card Number" required />} />
              <Stack direction="row" spacing={2}>
                <Controller
                  name="expiryDate"
                  control={control}
                  render={({ field }) => <TextField {...field} label="Expiry Date (MM/YY)" required />}
                />
                <Controller name="cvv" control={control} render={({ field }) => <TextField {...field} label="CVV" required />} />
              </Stack>
              <Button type="submit" variant="contained" color="primary" disabled={loadingChangePayment}>
                {loadingChangePayment ? "Updating..." : "Update"}
                <CircularProgress
                  hidden={loadingChangePayment}
                  color="primary"
                  size={24}
                  sx={{ display: loadingChangePayment ? "block" : "none", px: 3 }}
                />
              </Button>
            </Stack>
          </form>
        </Box>
      </Modal>
      <Modal open={openReceiptModal} onClose={handleCloseReceiptModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            minWidth: "390px",
            maxWidth: "800px",
            bgcolor: "background.paper",

            boxShadow: 24,
            p: 4,
          }}
        >
          <Receipt1 receiptData={receiptData || null} entity_name={entity_name} />
        </Box>
      </Modal>
    </>
  );
}

function ViewDocument({ documentName, selectedDocumentUrl, openModal, handleCloseDocModal }) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    bgcolor: "background.paper",

    boxShadow: 24,
    p: 4,
  };

  console.log("selectedDocumentUrl: ", selectedDocumentUrl);
  return (
    <Modal
      open={openModal}
      onClose={handleCloseDocModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableEscapeKeyDown
    >
      {/* <Stack id="stack-summary" spacing={2} sx={{ maxWidth: "1000px", overflowY: "hidden", p: 5 }}> */}
      <Box sx={style}>
        <Stack direction="row" spacing={2} display={"flex"} justifyContent={"space-between"} mb={3}>
          <Typography variant="h6">{documentName}</Typography>
          <IconButton onClick={handleCloseDocModal}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <PdfViewer2 documentUrl={selectedDocumentUrl} />
      </Box>
      {/* </Stack> */}
    </Modal>
  );
}
