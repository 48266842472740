import supabase from "../config/supabaseClient";

export async function getFileUrl(sbStorageBucket, filePath) {
  const { data } = supabase.storage.from(sbStorageBucket).getPublicUrl(filePath);

  console.log("file url: ", data.publicUrl);

  const fileUrl = data.publicUrl;

  return fileUrl;
}

// works for files in private buckets - signed URLs are required
export async function getSignedFileUrl(bucket, path) {
  console.log("signed URL: ", bucket, path);

  // private bucket
  const { data, error } = await supabase.storage.from(bucket).createSignedUrl(path, 3600);

  if (error) {
    console.error(error.message);
  } else {
    console.log("signed URL: ", data.signedUrl);
    return data.signedUrl;
  }
}

export async function handleDownloadFile(sbStorageBucket, file) {
  console.log("clicked file", file);

  try {
    // get the file from the entity_storage_bucket
    const { data: downloadFileData, error } = await supabase.storage.from(sbStorageBucket).download(file);
    if (error) {
      console.log("download error", error);
      return;
    }

    // if there is a file, trigger a download
    if (downloadFileData) {
      console.log("download data", downloadFileData);

      // Trigger a download of the file
      const url = window.URL.createObjectURL(downloadFileData);
      const link = document.createElement("a");
      link.href = url;

      link.download = file.split("/")[1];
      link.click();

      URL.revokeObjectURL(url);
    }
  } catch (error) {
    console.log("error", error);
  }
}

export async function handleDeleteFile(sbStorageBucket, file, entity_file_id) {
  console.log("sbStorageBucket", sbStorageBucket);
  console.log("file", file);
  console.log("entity_file_id", entity_file_id);

  try {
    // delete the file from the entity_storage_bucket
    const { data: deleteFileData, error } = await supabase.storage.from(sbStorageBucket).remove([file]);
    if (error) {
      console.log("delete error", error);
      return;
    }

    // if there is a file, trigger a download
    if (deleteFileData) {
      console.log("delete data", deleteFileData);
    }

    // delete the file from the entity_files table
    const { error: deleteEntityFileError } = await supabase.from("entity_file").delete().eq("id", entity_file_id);

    if (deleteEntityFileError) {
      console.log("delete error", deleteEntityFileError);
      return;
    }
  } catch (error) {
    console.log("error", error);
  }
}

// create payment record

// create receipt

export default { handleDownloadFile, handleDeleteFile };
