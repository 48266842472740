import PropTypes from "prop-types";
import { Document, Page, Text, View, StyleSheet, PDFViewer, Font, Line, Image } from "@react-pdf/renderer";
import { Box, Chip, Divider, Stack, Typography } from "@mui/material";
import { useMemo } from "react";
import { CreditCard } from "@mui/icons-material";

// import { Box, Divider, Stack } from "@mui/material";

export default function Receipt1({ receiptData, entity_name }) {
  // Register font
  // Font.register({ family: "Roboto", src: source });

  const amount = receiptData.amount / 100;
  const created_at = receiptData.created_at;

  // memoize the hst calculation
  const hst = useMemo(() => {
    return (amount - amount / 1.13).toFixed(2);
  }, [amount]);

  const subtotal = useMemo(() => {
    return (amount / 1.13).toFixed(2);
  }, [amount]);

  console.log(created_at, amount, entity_name);

  // Create styles
  const styles = StyleSheet.create({
    page: {
      flexDirection: "column",
      backgroundColor: "#FFFFFF",
      border: "1px solid black",
    },

    header: {
      backgroundColor: "#000000",
      color: "#FFFFFF",
      padding: 10,
      textAlign: "center",
    },
    title: {
      fontSize: 24,
      textAlign: "center",
    },

    section: {
      flexDirection: "row",
      justifyContent: "space-between",
      columnGap: 10,
      margin: 10,
      padding: 10,
      fontSize: 18,
    },
    line_item: {
      flexDirection: "column",
      alignContent: "flex-start",
      columnGap: 10,
      margin: 10,
      padding: 10,
    },
    transaction_details: {
      flexDirection: "column",
      alignItems: "center",
      columnGap: 10,
      margin: 10,
      padding: 10,
      fontSize: 15,
      borderBottom: "1px solid grey",
    },
    currency_section: {
      flexDirection: "row",
      justifyContent: "flex-end",
      columnGap: 10,
      margin: 10,
      padding: 10,
    },

    totals: {
      flexDirection: "column",
      alignItems: "flex-end",
      columnGap: 10,
      margin: 10,
      padding: 10,
      gap: 5,
      borderTop: "1px solid grey",
    },

    image: {
      width: 200,
      alignSelf: "center",
      marginTop: 10,
    },
  });

  return (
    <Stack p={2} height={"95%"} spacing={3}>
      <Stack display={"flex"} flexDirection={"row"} justifyContent="space-between" flexWrap={"wrap"} alignItems="center" marginBottom={5}>
        <img src="/skylaunch_logo.svg" width={150}></img>
        <Typography variant="h6">Payment Receipt</Typography>
      </Stack>
      <Divider />
      <Stack direction="row" justifyContent="space-between" alignItems="center" marginY={3}>
        <Box>
          <Typography>Standard Incorporation Package</Typography>
          <Typography>{entity_name}</Typography>
        </Box>
        <Box>
          <Typography>{new Date(created_at).toLocaleDateString()}</Typography>
        </Box>
      </Stack>
      <Divider />
      <Stack direction="row" justifyContent="end" spacing={"10px"} alignItems="center">
        <Box display={"flex"} flexDirection={"column"} alignItems={"end"}>
          <Typography fontSize={16}>Subtotal: {subtotal}</Typography>
          <Typography fontSize={16}>HST: {hst}</Typography>
          <Typography fontSize={16}>Total: {amount.toLocaleString("en-US", { style: "currency", currency: "CAD" })}</Typography>
        </Box>
      </Stack>
      <Stack height={"80%"} display={"flex"} direction={"column"} justifyContent={"end"} spacing={3}>
        <Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
          <Stack direction="row" justifyContent="start" alignItems="center" spacing={2}>
            <CreditCard />
            <Typography fontSize={13}>
              {receiptData.brand}:{receiptData.last4}
            </Typography>
          </Stack>
          {receiptData.status === "CAPTURED" ? <Chip label="Paid" color="success" /> : <Chip label="Pending" color="warning" />}
          {/* <Chip label="Paid" color="success" /> */}
        </Box>
        <Typography fontSize={10}>Transaction ID: {receiptData.gp_transaction_id}</Typography>
        <Divider />
        <Typography fontSize={16} fontWeight={600} textAlign={"center"}>
          Thank you for your business!
        </Typography>
      </Stack>
    </Stack>
    // <PDFViewer showToolbar="true" width="100%" height="100%">
    //   <Document>
    //     <Page size="A4" style={styles.page}>
    //       <View style={styles.header}>
    //         <Text>Payment Receipt</Text>
    //       </View>
    //       <Image src="skylaunch-logo.png" style={styles.image} />

    //       <View style={styles.transaction_details}>
    //         <Text>Transaction Date: {created_at}</Text>
    //         <Text>Skylaunch, Inc.</Text>
    //       </View>

    //       <View style={styles.section}>
    //         <Text>Incorporation Service For: {entity_name}</Text>
    //       </View>
    //       <Line />
    //       <View style={styles.totals}>
    //         <Text>
    //           {"Subtotal: $ "}
    //           {(amount / 1.13).toFixed(2)}
    //         </Text>
    //         <Text>
    //           {"HST: $ "}
    //           {(amount - amount / 1.13).toFixed(2)}
    //         </Text>
    //       </View>
    //       <View style={styles.totals}>
    //         <Text>
    //           {"Total: $ "}
    //           {amount}
    //         </Text>
    //       </View>
    //       <View style={styles.currency_section}></View>
    //       <View style={styles.currency_section}></View>
    //     </Page>
    //   </Document>
    // </PDFViewer>
  );
}

Receipt1.propTypes = {
  receiptData: PropTypes.object.isRequired,
  entity_name: PropTypes.string.isRequired,
};
