import React, { useEffect } from "react";
import supabase from "../config/supabaseClient";
import { useAuth } from "../contexts/useAuth";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Select,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import { useNavigate } from "react-router-dom";
import MuiAlert from "@mui/material/Alert";
import toast from "react-hot-toast";
import axios from "axios";

//? =========================================================================
//? Environment Config
//? =========================================================================
const environmentConfig = {
  development: {
    redirectURL: import.meta.env.VITE_SERVER_DEV_URL,
    messagingServerUrl: "http://localhost:3005",
  },
  production: {
    redirectURL: import.meta.env.VITE_SERVER_PROD_URL,
    messagingServerUrl: "https://skylaunch-api-messaging-production.up.railway.app",
  },
};

const env = import.meta.env.VITE_APP_MODE;
const { redirectURL, messagingServerUrl } = environmentConfig[env];

console.log("messagingServerUrl", messagingServerUrl);

// Customized Alert component
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

// Update user profile data in supabase
const updateUserData = async (userData) => {
  const { data, error } = await supabase.auth.updateUser({
    data: userData,
  });

  if (error) {
    console.log("error", error);
    return;
  }
  console.log("data length", data.length);
};

export default function Profile() {
  const navigate = useNavigate();

  const { user } = useAuth();
  const [userData, setUserData] = React.useState({
    name_first: "",
    name_last: "",
    phone: "",
    consent_marketing: false,
  });
  const [open, setOpen] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  // Create courier profile
  async function createCourierProfile() {
    // const courierProfileUrl = `https://api.courier.com/profiles/${user.id}`;
    const url = `${messagingServerUrl}/update-profile`;

    const body = {
      url: `https://api.courier.com/profiles/${user.id}`,
      courier_body: [
        {
          op: "replace",
          path: "/email",
          value: user.email,
        },
        {
          op: "replace",
          path: "/name",
          value: `${userData.name_first} ${userData.name_last}`,
        },
      ],
    };

    const options = {
      method: "POST",

      url: "http://localhost:3005/update-profile",

      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(body),
    };

    try {
      const response = await fetch(url, options);

      console.log("options", options);
      console.log("body", body);
      console.log("response", response);
      if (!response.ok) {
        toast.error("Failed to create courier profile");
        throw new Error("Failed to create courier profile");
      } else {
        toast.success("Messaging profile created");
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  const handleSaveProfile = () => {
    setOpen(true);
    updateUserData(userData);
    createCourierProfile();
  };

  async function getUserData() {
    // get user profile data
    const { data, error } = await supabase.auth.getUser();

    if (error) {
      console.log(error);
      return;
    }
    // console.log("user profile data", data);
    setUserData(() => ({
      ...userData,
      name_first: data.user.user_metadata.name_first,
      name_last: data.user.user_metadata.name_last,
    }));
  }

  const handleResetPassword = async () => {
    if (user.email === "") {
      toast.error("Please enter an email");
      return;
    }

    try {
      const { data, error } = await supabase.auth.resetPasswordForEmail(user.email, {
        redirectTo: redirectURL + "/resetpassword",
      });
      if (error) {
        console.log("reset email error", error);
        toast.error("Error sending reset email");
      }
      if (data) {
        console.log("data", data);
        toast.success(`Reset email sent to ${user.email}`);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    if (!user) {
      navigate("/login");
      return;
    }

    const update = async () => {
      await user;
      setUserData(user.user_metadata);
    };
    update();
  }, [user]);

  // console.log(userData);

  return (
    <>
      {userData && (
        <Stack direction="row" spacing={3} p={3} justifyContent={"center"}>
          <Stack direction="column" spacing={3}>
            <Card variant="outlined" sx={{ minHeight: "fit-content", bgcolor: "card.main" }}>
              <CardHeader title="My Profile" />
              <CardContent>
                <Box display={"flex"} flexDirection={"column"} rowGap={3} columnGap={3} mb={3}>
                  <Typography variant="h6">Email: {user?.email}</Typography>
                  <FormControl>
                    <TextField
                      fullWidth
                      name="name_first"
                      label="First Name"
                      value={userData?.name_first ? userData.name_first : ""}
                      onChange={(e) => setUserData({ ...userData, name_first: e.target.value })}
                    />
                  </FormControl>
                  <FormControl>
                    <TextField
                      name="name_last"
                      label="Last Name"
                      value={userData.name_last ? userData.name_last : ""}
                      onChange={(e) => setUserData({ ...userData, name_last: e.target.value })}
                    />
                  </FormControl>
                  <FormControl>
                    <TextField
                      name="phone"
                      label="Phone"
                      value={userData.phone ? userData.phone : ""}
                      onChange={(e) => setUserData({ ...userData, phone: e.target.value })}
                    />
                  </FormControl>
                </Box>
                <Box display={"flex"} flexDirection={"row"} rowGap={3} columnGap={3} mb={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={userData.consent_marketing}
                        onChange={(e) => setUserData({ ...userData, consent_marketing: e.target.checked })}
                      />
                    }
                    label="Keep me updated on new features and promotions"
                  />
                </Box>
                <Box flexDirection={"row"} display={"flex"} justifyContent={"flex-end"}>
                  {/* <Button variant="contained" color="primary" onClick={() => updateUserData(userData)}> */}
                  <Button variant="contained" color="primary" onClick={handleSaveProfile}>
                    Save
                  </Button>
                </Box>
              </CardContent>
            </Card>
            <Card variant="outlined" sx={{ minHeight: "fit-content", bgcolor: "card.main" }}>
              <CardHeader title="Change Password" />
              <CardContent>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  rowGap={3}
                  columnGap={3}
                  mb={3}
                  border={"1px solid lightgray"}
                  bgcolor={"#faf7be"}
                  p={2}
                  borderRadius={2}
                >
                  <Typography variant="body1">
                    To reset your password, click &quot;Send Password Reset&quot; below. A reset link will be sent to:
                  </Typography>
                  <Typography variant="h6">{user?.email}</Typography>
                </Box>
                <Box flexDirection={"row"} display={"flex"} justifyContent={"flex-end"}>
                  {/* <Button variant="contained" color="primary" onClick={() => updateUserData(userData)}> */}
                  <Button variant="contained" color="primary" onClick={handleResetPassword}>
                    Send Password Reset
                  </Button>
                </Box>
              </CardContent>
            </Card>
            <Button variant="outlined" color="primary" onClick={() => createCourierProfile()} sx={{ width: "fit-content" }}>
              Create Courier Profile
            </Button>
          </Stack>
        </Stack>
      )}
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: "top", horizontal: "right" }}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Profile Updated
        </Alert>
      </Snackbar>
      {/* <pre>{JSON.stringify(userData, null, 2)}</pre> */}
    </>
  );
}
