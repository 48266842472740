import { AppBar, Button, Container, Fade, Hidden, Paper, Stack, TextField, Toolbar, Typography, styled } from "@mui/material";
import supabase from "../config/supabaseClient";
import { useEffect, useState } from "react";
import { useAuth } from "../contexts/useAuth";
import { useNavigate } from "react-router-dom";
import { red } from "@mui/material/colors";
import toast from "react-hot-toast";

const StyledToolbar = styled(Toolbar)({
  display: "flex",
  justifyContent: "space-between",
});

export default function ResetPassword() {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [newpassword, setNewpassword] = useState("");
  const [pageLoaded, setPageLoaded] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState(false);

  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    // console.log(newpassword);

    try {
      const { data, error } = await supabase.auth.updateUser({ password: newpassword });
      console.log("data", data);

      if (error) {
        console.log("try error", error.message);
        setErrorMessage(error.message);
        setIsLoading(false);
      }

      if (data.user) {
        console.log("try data", data);
        toast.success("Password Reset Successful");
        navigate("/dashboard/?pwdresetsuccess=true");
      }
      setIsLoading(false);
    } catch (error) {
      console.log("catch error", error);
      setIsLoading(false);
    }
  };

  const getUserData = async () => {
    // get user profile data
    const { data, error } = await supabase.auth.getUser();

    if (error) {
      console.log(error);
      return;
    }
    console.log("user profile data", data);
  };

  // useEffect(() => {
  //   if (!user) {
  //     navigate("/login");
  //   }
  // }, [user]);

  return (
    <div>
      <AppBar position="sticky" sx={{ bgcolor: "white", borderRadius: 5, boxShadow: "none" }}>
        <StyledToolbar sx={{ justifyContent: "space-between" }}>
          <img
            src="skylaunch_logo.svg"
            alt="Sky Launch Logo"
            width="200px"
            height="80px"
            onClick={() => {
              navigate("/dashboard");
            }}
          />
        </StyledToolbar>
      </AppBar>
      <Container maxWidth="sm" sx={{ mt: 5 }}>
        <Fade in={pageLoaded} style={{ transitionDelay: pageLoaded ? "250ms" : "0ms" }}>
          <Paper elevation={4} sx={{ p: 2, bgcolor: "card.main" }}>
            <Typography variant="h5" sx={{ mb: 2 }} textAlign={"center"}>
              Reset Password
            </Typography>
            <form>
              <Stack direction="column" spacing={2}>
                <TextField
                  name="newpassword"
                  id="newpassword"
                  label="New Password"
                  variant="outlined"
                  type="password"
                  onChange={(e) => setNewpassword(e.target.value)}
                />
                {ErrorMessage && (
                  <Typography variant="subtitle1" color={red[500]}>
                    {ErrorMessage}
                  </Typography>
                )}
                <Button
                  loading={isLoading}
                  disabled={isLoading}
                  variant="contained"
                  sx={{ mt: 5 }}
                  onClick={handleSubmit} // onClick={formik.handleSubmit}
                >
                  Set New Password
                </Button>
                <Button variant="contained" sx={{ display: "none", mt: 5 }} onClick={getUserData}>
                  Get User Data
                </Button>
              </Stack>
            </form>
          </Paper>
        </Fade>
        {/* <pre>{JSON.stringify(user, null, 2)}</pre> */}
      </Container>
    </div>
  );
}
