import { Box, Button, Chip, Divider, Stack, TextField, Typography, Zoom } from "@mui/material";
import React, { useEffect, useMemo } from "react";
import { Link, redirect, useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/useAuth";

const product = 699;
const annualMaintenance = 120;
const subscriptionDiscount = 50;

export default function StripeTest() {
  const [stripeCheckoutUrl, setStripeCheckoutUrl] = React.useState("");
  const [checkoutError, setCheckoutError] = React.useState(false);
  const [isAnnualMaintenance, setIsAnnualMaintenance] = React.useState(false);

  // total display states
  const [oneTimeProduct, setOneTimeProduct] = React.useState(product);
  const [subtotal, setSubtotal] = React.useState(oneTimeProduct);

  // memoized values
  const taxTotal = useMemo(() => subtotal * 0.13, [subtotal]);
  const total = useMemo(() => subtotal + taxTotal, [subtotal, taxTotal]);
  const gpAmount = useMemo(() => total.toFixed(2).replace(".", ""), [total]);

  const redirect = useNavigate();

  const eid = "12345";

  const payload_option1 = {
    line_items: [
      {
        price: "price_1PCRIgP4zxlQZjOIeOGAS6pp",
        quantity: 1,
        tax_rates: ["txr_1PCQzIP4zxlQZjOIKEZ3svI9"],
      },
    ],
    mode: "payment",
    customer_email: "option1@test.com",
    clientReferenceId: eid,
    metadata: {
      eid: eid,
      type: "option1",
    },
    cancel_path: window.location.pathname,
  };

  const payload_option2 = {
    line_items: [
      {
        price: "price_1PEFNfP4zxlQZjOIGl2zEEse",
        quantity: 1,
        tax_rates: ["txr_1PCQzIP4zxlQZjOIKEZ3svI9"],
      },
      {
        price: "price_1PDoioP4zxlQZjOIirgAewsK",
        quantity: 1,

        tax_rates: ["txr_1PCQzIP4zxlQZjOIKEZ3svI9"],
      },
    ],
    mode: "subscription",
    // subscription_data: {
    //   items: [{ plan: "plan_Q4Od5kDKUuidGB" }], // Incorporation Package
    //   trial_end: Math.floor(Date.now() / 1000) + 60 * 60 * 24 * 60, // 60 days from now
    // },
    clientReferenceId: eid,
    metadata: {
      eid: eid,
      type: "option1",
    },

    customer_email: "option2@test.com",
    eid: eid,
    cancel_path: window.location.pathname,
  };

  const payload = React.useMemo(() => {
    if (isAnnualMaintenance) {
      return payload_option2;
    }
    return payload_option1;
  }, [isAnnualMaintenance]);

  const { user } = useAuth();

  function handlePressAnnualMaintenance() {
    setIsAnnualMaintenance((current) => !current);

    console.log("payload", payload);
  }

  async function handleCheckout() {
    console.log("payload in handleCheckout", payload);
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: myHeaders,
      body: JSON.stringify(payload),
    };

    try {
      setCheckoutError(false);
      const stripe_response = await fetch("http://localhost:4242/create-checkout-session", requestOptions);
      const stripe_response_json = await stripe_response.json();

      console.log("stripe_response_json", stripe_response_json.url);

      setStripeCheckoutUrl(stripe_response_json.url);

      //redirect the user to the stripe checkout page
      if (stripe_response_json.url) {
        window.location.href = stripe_response_json.url;
      }
    } catch (error) {
      console.error("Catch Error:", error);

      setCheckoutError(true);
    }

    console.log(stripeCheckoutUrl);
  }

  useEffect(() => {
    if (isAnnualMaintenance) {
      setOneTimeProduct(product - subscriptionDiscount);
      setSubtotal(oneTimeProduct + annualMaintenance - subscriptionDiscount);
      return;
    } else {
      setOneTimeProduct(product);
      setSubtotal(oneTimeProduct);
    }
  }, [isAnnualMaintenance]);

  return (
    <Stack gap={3} p={3}>
      <div>Stripe Test</div>

      <Stack direction={"column"} gap={3}>
        <Box border={2} borderColor={"lightgrey"} borderRadius={1} p={3}>
          <Stack direction={"row"} gap={3} justifyContent={"space-between"}>
            <Box>
              <Typography variant="h6">Incorporation Package</Typography>
            </Box>
            <Stack direction={"column"} gap={1}>
              <Typography variant="h4" align="right">
                ${oneTimeProduct}
              </Typography>
              <Typography variant="subtitle1" align="right">
                One-time
              </Typography>
              <Zoom in={isAnnualMaintenance} timeout={100} style={{ transitionDelay: isAnnualMaintenance ? "200ms" : "0ms" }} unmountOnExit>
                <Chip
                  label={`You saved: -$${subscriptionDiscount}`}
                  sx={{ color: "black", bgcolor: "chip.green", marginBottom: 1, visibility: isAnnualMaintenance ? "visible" : "hidden" }}
                />
              </Zoom>
            </Stack>
          </Stack>
        </Box>
        <Stack direction={"column"} gap={3}>
          <Box
            border={2}
            borderColor={"lightgrey"}
            borderRadius={1}
            p={3}
            sx={{
              ":hover": {
                borderColor: "grey",
              },
              cursor: "pointer",
              bgcolor: isAnnualMaintenance ? "#3A589620" : "",
            }}
            transition="all 0.3s"
            onClick={handlePressAnnualMaintenance}
          >
            <Stack direction={"row"} justifyContent={"space-between"} gap={1}>
              <Stack direction={"column"} gap={1}>
                <Box>
                  <Chip label="Save $50 on Incorporation Package" sx={{ color: "black", bgcolor: "chip.green", marginBottom: 1 }} />
                </Box>
                <Typography variant="h6">Annual Maintenance</Typography>
                <Typography variant="body1">Select Annual Maintenance</Typography>
              </Stack>
              <Stack direction={"column"} gap={1}>
                <Typography variant="h4">${annualMaintenance}</Typography>
                <Typography variant="subtitle1" align="right">
                  Per Year
                </Typography>
              </Stack>
            </Stack>
          </Box>
        </Stack>
        <Stack direction={"row"} gap={3} justifyContent={"end"}>
          <Box>
            <Button variant="contained" onClick={handleCheckout}>
              Payment
            </Button>
          </Box>
        </Stack>
        <pre>{stripeCheckoutUrl}</pre>
        {stripeCheckoutUrl && (
          <Link to={stripeCheckoutUrl} target="_blank">
            Checkout
          </Link>
        )}
        {checkoutError && <div>Error checking out</div>}
      </Stack>
    </Stack>
  );
}
