import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "100px",
          TextTransform: "none",

          // Set border radius to 100px
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#3A5896",
      light: "#3E81B6",
      dark: "#4479ba",
    },
    secondary: {
      main: "#3B9797",
      light: "##65d1cf",
      dark: "#2e5f59",
    },
    notification: {
      main: "#f44336",
      light: "##65d1cf",
      dark: "#2e5f59",
    },
    otherColor: {
      main: "#ffeb3b",
      light: "#fff176",
      dark: "#fbc02d",
    },
    card: {
      main: "#fafafa",
      light: "#ffffff",
      dark: "#c7c7c7",
      border: "#c7c7c7",
      white: "#ffffff",
      green: "#4CAF50",
      lightblue: "#e4edff",
    },
    icon: {
      main: "#000000",
      light: "#ffffff",
      dark: "#c7c7c7",
      red: "#f44336",
      green: "#4CAF50",
      blue: "#3A5896",
    },
    chip: {
      green: "#12b886",
      red: "#f44336",
      orange: "#fab005",
    },
    entityStatuses: {
      new: "#FFC107",
      inProgress: "#4CAF50",
      completed: "#4FC3F7",
      error: "#f44336",
      processing: "#700095",
      assigned: "#700095",
    },
    customButton: {
      main: "#3A5896",
      light: "#6dc4f9",
      dark: "#4479ba",
      red: "#f44336",
      green: "#4CAF50",
    },
    tab: {
      main: "#fafafa",
      selected: "#3A589610",
    },
  },
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});
