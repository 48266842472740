import { createClient } from "@supabase/supabase-js";

// import environmental variables
const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseKey = import.meta.env.VITE_ANON_KEY;

// create a supabase client
const supabase = createClient(supabaseUrl, supabaseKey);

// export supabase client
export default supabase;
