import React, { useEffect, useState } from "react";
import supabase from "../../../config/supabaseClient";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Chip, CircularProgress, Fade, Stack, Typography } from "@mui/material";
import LoadingSkeleton from "../../LoadingSkeleton";
import { Check, CheckCircleOutline } from "@mui/icons-material";

export default function ShareClassesTable({ entityId }) {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log("entityId", entityId);
    const getData = async () => {
      setLoading(true);
      const { data: shareClassData, error: shareClassError } = await supabase
        .from("share_class")
        .select("*")
        .eq("entity_id", entityId)
        .order("created_at", { ascending: true });

      if (shareClassError) console.log("shareClassError", shareClassError);
      if (shareClassData) setData(shareClassData);
      setLoading(false);
    };
    getData();

    //cleanup
    return () => {
      setData({});
    };
  }, [entityId]);

  if (loading)
    return (
      <Fade in={true} timeout={500} transitionDelay={500}>
        <Box sx={{ height: "100px", width: "100%" }}>
          <LoadingSkeleton />
        </Box>
      </Fade>
    );
  return (
    <Fade in={true} timeout={500} transitionDelay={500}>
      <Stack>
        {data.length > 0 ? (
          <TableContainer sx={{ maxWidth: "1000px", bgcolor: "card.main", border: 1, borderColor: "divider" }}>
            <Table stickyHeader aria-label="share register table" fullWidth>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Certificated</TableCell>
                  <TableCell>Voting</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
                {data.map((shareClass, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Chip label={shareClass.name} variant="filled" sx={{ bgcolor: "lightgray" }} />
                    </TableCell>
                    <TableCell>{shareClass.is_certificated ? <CheckCircleOutline color="success" /> : null}</TableCell>
                    <TableCell>{shareClass.is_voting ? <CheckCircleOutline color="success" /> : null}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Fade in={true} timeout={500} transitionDelay={500}>
            <Typography variant="h6">No share classes found</Typography>
          </Fade>
        )}
      </Stack>
    </Fade>
  );
}
