import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";

import dayjs from "dayjs";
import { useFormik } from "formik";
import * as yup from "yup";
import months from "../data/months.json";
import { HelpPopover } from "../components/HelpPopover";
import { useEffect, useState } from "react";
import { useAuth } from "../contexts/useAuth";
import supabase from "../config/supabaseClient";
import { useParams } from "react-router-dom";
import { ExpandMore } from "@mui/icons-material";

export default function Step5({ activeStep, setActiveStep, handleNext, handleBack, updateEntityLastStep }) {
  const { eid } = useParams();
  // const { user } = useAuth();
  const [fiscalYearEndMonth, setFiscalYearEndMonth] = useState("");
  const [errorMissingFiscalYearEndMonth, setErrorMissingFiscalYearEndMonth] = useState(false);
  const [entityData, setEntityData] = useState({});
  const [selectedMonthNumber, setSelectedMonthNumber] = useState(0);
  const [isShortYear, setIsShortYear] = useState(false);

  const currentMonthNumber = dayjs().month() + 1;

  const handleClickNext = async (event) => {
    event.preventDefault();

    // check if form is valid
    if (entityData.fiscal_year_end_month === "") {
      setErrorMissingFiscalYearEndMonth(true);
      return;
    } else {
      setErrorMissingFiscalYearEndMonth(false);
    }

    // FIXME: validate step before this

    // Step specific logic here
    console.log("updating entity", eid, entityData.fiscal_year_end_month);

    const { data, error } = await supabase
      .from("entity")
      .update({
        fiscal_year_end_month: entityData.fiscal_year_end_month,
      })
      .eq("id", eid)
      .single();

    if (data) {
      console.log(data);
    }

    if (error) {
      console.log(error);
    }
    updateEntityLastStep(5);
    handleNext();
  };

  const handleClickBack = () => {
    handleBack();
  };

  const handleFiscalYearEndMonthChange = (e) => {
    setEntityData({ ...entityData, fiscal_year_end_month: e.target.value });
    setErrorMissingFiscalYearEndMonth(false);
    setSelectedMonthNumber(months.find((month) => month.name === e.target.value).id);

    const selectedMonth = months.find((month) => month.name === e.target.value).id;

    // Check if the year is short
    console.log("currentMonthNumber", currentMonthNumber);
    console.log("selectedMonthNumber", selectedMonthNumber);
    console.log("diff", selectedMonth - currentMonthNumber);
    if (selectedMonth - currentMonthNumber < 12 && selectedMonth - currentMonthNumber > 0) {
      setIsShortYear(true);
    } else {
      setIsShortYear(false);
    }
  };

  useEffect(() => {
    const getEntityData = async () => {
      const { data, error } = await supabase.from("entity").select("*").eq("id", eid).select();

      if (error) {
        console.log("error", error);
        return;
      }
      console.log("data length", data.length);

      if (data.length > 0) {
        // First record Only
        console.log("got data, not null!");
        console.log("data", data);
        setEntityData(data[0]);
      }
    };

    getEntityData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Typography mb={3} variant="h5">
        Confirm Financial Year End
      </Typography>
      <Box display={"flex"} flexDirection={{ xs: "column", sm: "row" }} rowGap={3} columnGap={3} alignItems={"center"} mb={3}>
        <Stack direction="row" spacing={2} justifyContent={"space-between"} alignItems={"center"}>
          <Typography variant="subtitle1">I would like the financial year for my company to end on the last day of: </Typography>
        </Stack>

        <Stack direction="row" spacing={2} justifyContent={"space-between"} alignItems={"center"}>
          <FormControl variant="outlined" sx={{ width: "200px" }}>
            <InputLabel htmlFor="fiscalYearEndMonth">Fiscal Year End</InputLabel>

            <Select
              variant="outlined"
              label="Fiscal Year End"
              name="fiscalYearEndMonth"
              // value={entityData.fiscal_year_end_month ? entityData.fiscal_year_end_month : "January"}
              value={entityData.fiscal_year_end_month ? entityData.fiscal_year_end_month : ""}
              onChange={(e) => handleFiscalYearEndMonthChange(e)}
              // error={errorMissingFiscalYearEndMonth ? false : true}
              error={entityData.fiscal_year_end_month ? false : true}
            >
              {months.map((month) => (
                <MenuItem key={month.id} value={month.name}>
                  {month.name}
                </MenuItem>
              ))}
            </Select>
            <Typography variant="body2" color="error">
              {errorMissingFiscalYearEndMonth ? "Please select a fiscal year end." : ""}
            </Typography>
          </FormControl>
          <HelpPopover>
            <Stack spacing={2} p={5} maxWidth={390}>
              <Typography>
                A corporation’s financial year is also its fiscal period for accounting and tax purposes. A corporation’s first financial year begins
                on the date of incorporation and ends on a year end date chosen by the owner. But a financial year cannot be longer than 53 weeks (371
                days).
              </Typography>
              <Typography>
                The most common financial year end dates are <b>March 31</b>, <b>June 30</b>, <b>September 30</b> and <b>December 31</b>. Consider
                choosing a financial year end date that falls just within a year from incorporation date so you have as much time as possible to file
                your initial T2 tax return without going over the 53-week limit.
              </Typography>
              <Typography>
                If you decide now, we will include this in your corporation’s organizational resolutions so you’ll be all set. You can also decide
                later by declaring your financial year end date on your first T2 return after incorporation (which is due within 6 months of your
                company’s financial year).
              </Typography>
            </Stack>
          </HelpPopover>
        </Stack>
      </Box>
      {/* {entityData.fiscal_year_end_month && isShortYear && (
        <Box marginBottom={5}>
          <Alert severity="info">
            <Typography fontSize={20} fontWeight={600}>
              Short Year
            </Typography>
            <Typography variant="body2">
              Due to the timing of your fiscal year end, you may have a short year and will need to file a short year return. A short year is a fiscal
              year that is less than 12 months long.
            </Typography>
          </Alert>
        </Box>
      )} */}

      <Stack direction="row" spacing={2} justifyContent={"space-between"} mb={3}>
        <Button variant="contained" color="primary" onClick={handleClickBack}>
          Back
        </Button>
        <Button variant="contained" color="primary" onClick={handleClickNext}>
          Next
        </Button>
      </Stack>
      {/* <Typography variant="body2" mt={3} mb={10}>
        {fiscalYearEndMonth ? `Your fiscal year end is ${fiscalYearEndMonth}` : "Please select a fiscal year end."}
      </Typography> */}

      {/* <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />}>Short Year Logic Debug</AccordionSummary>
        {entityData.fiscal_year_end_month && (
          <Box padding={3}>
            <Typography variant="body2" mt={1}>
              Current Month #: {currentMonthNumber}
            </Typography>
            <Typography variant="body2" mt={1}>
              Selected Month #: {selectedMonthNumber}
            </Typography>
            <Typography variant="body2" mt={1}>
              Is short year?: {isShortYear ? "Yes" : "No"}
            </Typography>
          </Box>
        )}
      </Accordion> */}
      {/* <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography variant="subtitle1">Debug</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <pre>entity:{JSON.stringify(entityData, null, 2)}</pre>
          <pre>Year End:{JSON.stringify(entityData.fiscal_year_end_month, null, 2)}</pre>
        </AccordionDetails>
      </Accordion> */}
    </>
  );
}
