import { Toolbar, Typography, styled, Box, Stack, Divider, List, ListItemButton, ListItem, ListItemText, Drawer } from "@mui/material";
import { Outlet, useNavigate, useSearchParams } from "react-router-dom";
// import { useAuth } from "../contexts/useAuth";;
// import { useAuth } from "../contexts/useAuth";
import { useEffect, useState } from "react";

import NavBar from "../components/NavBar";
import supabase from "../config/supabaseClient";
import dayjs from "dayjs";
import { Toaster } from "react-hot-toast";
import { AnimatePresence, motion } from "framer-motion";
import { useAuth } from "../contexts/auth";
import { CourierProvider } from "@trycourier/react-provider";
import { Toast } from "@trycourier/react-toast";

const StyledToolbar = styled(Toolbar)({
  display: "flex",
  justifyContent: "space-between",
});

const Icons = styled(Box)(({ theme }) => ({
  display: "none",
  alignItems: "center",
  gap: "20px",
  [theme.breakpoints.up("sm")]: {
    display: "flex",
  },
}));

const UserBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "20px",
  [theme.breakpoints.up("sm")]: {
    display: "none",
  },
}));

const drawerWidth = 240;

export default function Root() {
  const clientKey = import.meta.env.VITE_COURIER_CLIENT_KEY;

  const [entities, setEntities] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedEntity, setSelectedEntity] = useState("");

  const { user, signOut } = useAuth();
  const navigate = useNavigate();
  const [params] = useSearchParams();

  // Drawer related
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const drawer = (
    <Box sx={{ backgroundColor: "steelblue" }}>
      {/* <Toolbar /> */}
      <Box py={1}>
        <img src="/skylaunch_icon_white.svg" alt="Sky Launch Logo" width="80px" height="50px" onClick={() => navigate("/dashboard")} />
      </Box>
      <Divider />
      <Typography variant="h6" fontWeight={600} color={"white"} component="div" sx={{ p: 1 }}>
        My Companies
      </Typography>
      <List>
        {["Company 1", "Company 2", "Company 3", "Company 4"].map((text, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton>
              <ListItemText primary={text} sx={{ color: "white" }} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const textParamType = params.get("type");
  // console.log(textParamType);

  if (textParamType === "recovery") {
    navigate("/resetpassword");
  }

  function handleReturnToDashboard() {
    console.log("returning to dashboard...");
    setSelectedEntity("");
    navigate("/dashboard");
  }

  useEffect(() => {
    // console.log(params);
    // console.log("user:", user);
    if (!user) {
      // alert("You must be logged in to view this page");
      navigate("/login");
    } else {
      // Causes user to be redirected to dashboard when they are logged in on re-render
      // navigate("/dashboard");
      console.log("redirecting to dashboard...");
    }
  }, [user]);

  // useEffect(() => {
  //   if (user) {
  //     // Get entities from Supabase
  //     const fetchEntities = async () => {
  //       setIsLoading(true);

  //       // const user_id = user.id;
  //       // await user;
  //       // console.log("user", user);
  //       // const { data, error } = await supabase.from("entity").select("*").eq("linked_user", user_id).order("name", { ascending: true });
  //       const { data, error } = await supabase.from("entity").select("*").order("created_at", { ascending: false });

  //       if (error) console.log("error", error);

  //       if (data) {
  //         // console.log("data", data);
  //         setEntities(data);
  //         setIsLoading(false);
  //       }
  //     };
  //     fetchEntities();
  //   }

  //   // console.log("Dashboard: ", entities);
  // }, [user]);

  return (
    <>
      <CourierProvider userId={user?.id} clientKey={"M2JjZTdiMjctMDRjNy00NWZmLWE4OTctMWRiMTgwYmQxYzBl"} brandId={"Y7VZXQYZYX4V1PMRSCBV0Q3MDGPB"}>
        <div>
          {/* <Outlet context={[selectedEntity, setSelectedEntity]} /> */}
          <Box id="drawer-root" sx={{ display: "flex" }}>
            <Stack id="stack-nav-outlet" spacing={2} direction={"column"} sx={{ width: "100%", height: "100vh", overflowY: "auto" }}>
              <NavBar handleDrawerToggle={handleDrawerToggle} handleReturnToDashboard={handleReturnToDashboard} />
              {/* <pre>{JSON.stringify(user, null, 2)}</pre> */}
              <Outlet />
            </Stack>
          </Box>
          {/* </Container> */}
        </div>
        <Toaster />
      </CourierProvider>
    </>
  );
}
