// Devnotes
// 1. Update redirect URLs to use env variables

import {
  Button,
  Container,
  Paper,
  Stack,
  TextField,
  Typography,
  AppBar,
  Toolbar,
  styled,
  Fade,
  Modal,
  Box,
  Card,
  FilledInput,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";

import { useEffect, useState } from "react";
import supabase from "../config/supabaseClient";
import { useNavigate, Link as RouterLink, Link } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage, useFormik } from "formik";
// import { useAuth } from "../contexts/useAuth";;
import { useAuth } from "../contexts/useAuth";
import toast, { Toaster } from "react-hot-toast";
import { AutoAwesome, LockReset, Visibility, VisibilityOff } from "@mui/icons-material";

const modalStyle = {
  position: "absolute",
  top: "20%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  maxWidth: 400,
  minwidth: 390,
  bgcolor: "white",
  border: "0px solid #000",

  p: 4,
};

const loginSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email address").required("Required"),
  password: Yup.string().required("Required"),
});

const StyledToolbar = styled(Toolbar)({
  display: "flex",
  justifyContent: "space-between",
});

// const initialFormData = Object.freeze({
//   username: "",
//   password: "",
//   field1: "",
// });

// handle password reset

// Check AAL level of user
const checkAAL = async () => {
  const { data, error } = await supabase.auth.mfa.getAuthenticatorAssuranceLevel();
  const { currentLevel, nextLevel, currentAuthenticationMethods } = data;
  console.log("currentLevel", currentLevel);
  console.log("nextLevel", nextLevel);
  console.log("currentAuthenticationMethods", currentAuthenticationMethods);
};

// Enroll user in MFA: TOPT
const enrollMFA = async () => {
  const { data, error } = await supabase.auth.mfa.enroll({
    factorType: "totp",
  });

  // Use the id to create a challenge.
  // The challenge can be verified by entering the code generated from the authenticator app.
  // The code will be generated upon scanning the qr_code or entering the secret into the authenticator app.

  // console.log("id", id);
  // console.log("type", type);
  // console.log("qr_code", qr_code);
  // console.log("secret", secret);
  // console.log("uri", uri);
  if (error) {
    console.log("error", error);
  }

  if (data) {
    const {
      id,
      type,
      totp: { qr_code, secret, uri },
    } = data;
    console.log("No error in enrollMFA");
    return id;
  }
};

const getAllMFAFactors = async () => {
  const { data, error } = await supabase.auth.admin.mfa.listFactors();
  console.log("data", data);
  console.log("error", error);
};

// Main Component
export default function Login() {
  const { user } = useAuth();
  const { logIn } = useAuth();

  const [pageLoaded, setPageLoaded] = useState(true);
  // const [qrCode, setQrCode] = useState(null);
  const [email, setEmail] = useState("");
  const [resetPasswordError, setResetPasswordError] = useState(false);

  const [open, setOpen] = useState(false);
  const [openMagicLoginModal, setOpenMagicLoginModal] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleOpenMagicLoginModal = () => setOpenMagicLoginModal(true);
  const handleCloseMagicLoginModal = () => setOpenMagicLoginModal(false);

  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  // get location hostname
  // const redirectURL = window.location.hostname === "localhost" ? "http://localhost:5173/resetpassword" : "https://app.skylaunch.ca/resetpassword";
  // console.log("redirectURL", redirectURL);

  //? =========================================================================
  //? Environment Config
  //? =========================================================================
  const environmentConfig = {
    development: {
      redirectURL: import.meta.env.VITE_SERVER_DEV_URL,
    },
    production: {
      redirectURL: import.meta.env.VITE_SERVER_PROD_URL,
    },
  };

  const env = import.meta.env.VITE_APP_MODE;
  const { redirectURL } = environmentConfig[env];

  // console.log("redirectURL", redirectURL);

  //Formik config
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginSchema,
    onSubmit: async (values) => {
      const { error } = await logIn(values);
      if (error) {
        toast.error(error.message);
      }
      // handleSignIn(values);
    },
  });

  // not used
  const handleMFAEnroll = async () => {
    let enrollMFAData = await enrollMFA();
    console.log("enrollMFAData", enrollMFAData);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleResetPassword = async () => {
    if (email === "") {
      setResetPasswordError(true);
      toast.error("Please enter an email");
      return;
    }

    try {
      console.log("redirect to url used:", redirectURL + "/resetpassword");

      const { data, error } = await supabase.auth.resetPasswordForEmail(email, {
        redirectTo: redirectURL + "/resetpassword",
      });
      if (error) {
        console.log("reset email error", error);
      }
      if (data) {
        console.log("data", data);
        toast.success("Reset email sent");
        handleClose();
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleMagicLogin = async () => {
    if (email === "") {
      setResetPasswordError(true);
      toast.error("Please enter an email");
      return;
    }

    try {
      const { data, error } = await supabase.auth.signInWithOtp({
        email: email,
        options: {
          // set this to false if you do not want the user to be automatically signed up
          shouldCreateUser: false,
          redirectTo: redirectURL,
        },
      });
      if (error) {
        console.log("error", error);
      }
      if (data) {
        handleCloseMagicLoginModal();
        navigate(`/magic-link-next-steps?email=${email}`);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  function clickLogo() {
    window.location.href = "https://skylaunch.ca";
  }

  function loginAsUser(option) {
    console.log("option", option);
    switch (option) {
      case 1:
        formik.setValues({ email: "michael.lee@skylaunch.ca", password: "abc12345!" });
        console.log("formik.values", formik.values);
        break;
      case 2:
        formik.setValues({ ...formik.values, email: "marc@techstax.io", password: "123Secure!" });
        break;
      case 3:
        formik.setValues({ ...formik.values, email: "richard@techstax.io", password: "abc12345!" });
        break;
      default:
        formik.setValues({ ...formik.values, email: "marc@techstax.io", password: "123Secure!" });
        break;
    }
  }

  useEffect(() => {
    if (user) {
      navigate("/dashboard");
    }
  }, [user]);

  return (
    <>
      <AppBar position="sticky" sx={{ bgcolor: "white", borderRadius: 5, boxShadow: "none" }}>
        <StyledToolbar sx={{ justifyContent: "space-between" }}>
          <img src="skylaunch_logo_blue.svg" alt="Sky Launch Logo" width="200px" height="80px" onClick={clickLogo} />
        </StyledToolbar>
      </AppBar>
      <Container maxWidth="sm" sx={{ mt: 5 }}>
        <Fade in={pageLoaded} style={{ transitionDelay: pageLoaded ? "250ms" : "0ms" }}>
          <Paper elevation={1} sx={{ p: 5, bgcolor: "card.main" }}>
            {/* <Typography variant="h5" align="center" sx={{ mb: 2 }}>
              Login
            </Typography> */}
            <Stack direction="column" spacing={2} sx={{ mb: 2 }} alignItems={"center"}>
              <Card variant="outlined" sx={{ bgcolor: "card.white", borderRadius: 4, p: 1 }}>
                <img src="skylaunch_icon.svg" alt="Sky Launch Logo" width="80px" height="80px" />
              </Card>
            </Stack>
            <Stack direction="column" spacing={2} sx={{ mb: 2 }}>
              <form onSubmit={formik.handleSubmit}>
                <Stack direction="column" spacing={3}>
                  <TextField
                    placeholder="Email"
                    name="email"
                    id="email"
                    label="Email"
                    value={formik.values.email}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                    onChange={formik.handleChange}
                    inputProps={{ autoCapitalize: "none" }}
                  />
                  <OutlinedInput
                    placeholder="Password"
                    name="password"
                    id="password"
                    value={formik.values.password}
                    error={formik.touched.password && Boolean(formik.errors.password)}
                    onChange={formik.handleChange}
                    type={showPassword ? "text" : "password"}
                    inputProps={{ autoCapitalize: "none" }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <Button variant="contained" sx={{ mb: 2 }} type="submit">
                    Login
                  </Button>
                </Stack>
              </form>

              <Stack direction="column" spacing={2} sx={{ pt: 2 }}>
                {/* <Typography variant="body1" marginTop={5}>
                  Dont have an account? &nbsp;
                  <Link style={{ textDecoration: "none", color: "gray" }} component={RouterLink} to="/signup">
                    Sign Up
                  </Link>
                </Typography> */}
                <Stack direction={{ xs: "column", md: "row" }} spacing={3} justifyContent="space-between">
                  {/* <Box sx={{ justifyContent: "start" }}> */}
                  {/* <Typography variant="body1" onClick={handleOpen} sx={{ cursor: "pointer" }}> */}
                  <Button variant="outlined" onClick={handleOpen} startIcon={<LockReset />}>
                    Reset Password
                  </Button>
                  {/* </Typography> */}
                  {/* </Box> */}
                  {/* <Box sx={{ display: "flex", justifyContent: "start" }}> */}
                  {/* <Typography variant="body1" onClick={handleOpenMagicLoginModal} sx={{ cursor: "pointer" }} startIcon={<AutoAwesome />}> */}
                  <Button variant="outlined" onClick={handleOpenMagicLoginModal} startIcon={<AutoAwesome />}>
                    Passwordless Login
                  </Button>
                  {/* </Typography> */}
                  {/* </Box> */}
                </Stack>
                {/* <Stack
                  direction={"row"}
                  spacing={3}
                  sx={{ position: "absolute", bottom: "16px", right: "16px", display: "flex", justifyContent: "start" }}
                >
                  <Typography variant="body1" onClick={() => loginAsUser(1)} sx={{ cursor: "pointer" }}>
                    User1
                  </Typography>
                  <Typography variant="body1" onClick={() => loginAsUser(2)} sx={{ cursor: "pointer" }}>
                    User2
                  </Typography>
                  <Typography variant="body1" onClick={() => loginAsUser(3)} sx={{ cursor: "pointer" }}>
                    User3
                  </Typography>
                </Stack> */}
              </Stack>
            </Stack>
          </Paper>
        </Fade>
        <Toaster />
        {/* <pre>{JSON.stringify(formik.values, null, 2)}</pre> */}
      </Container>

      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={modalStyle}>
          <Stack direction="column" gap={2}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Reset Password
            </Typography>
            <TextField
              error={resetPasswordError}
              label={resetPasswordError ? "Please enter an email" : "Email"}
              required
              name="email"
              id="email"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />

            <Button variant="contained" sx={{ mt: 3 }} onClick={handleResetPassword}>
              Send Reset Email
            </Button>
          </Stack>
        </Box>
      </Modal>
      <Modal
        open={openMagicLoginModal}
        onClose={handleCloseMagicLoginModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Stack direction="column" gap={2}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Passwordless Login
            </Typography>
            <TextField
              error={resetPasswordError}
              label={resetPasswordError ? "Please enter an email" : "Email"}
              required
              name="email"
              id="email"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />

            <Button variant="contained" sx={{ mt: 3 }} onClick={handleMagicLogin} startIcon={<AutoAwesome />}>
              Send Login Link
            </Button>
            {/* <pre>{JSON.stringify(formik.values, null, 2)}</pre> */}
          </Stack>
        </Box>
      </Modal>
    </>
  );
}
