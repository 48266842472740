import { Help } from "@mui/icons-material";
import { Box, IconButton, Popover, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

export const HelpPopover = ({ children }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "help-popover" : undefined;

  return (
    <Box sx={{ p: 1 }}>
      <IconButton aria-describedby={id} onClick={handleClick}>
        <Help />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {children}
      </Popover>
    </Box>
  );
};

HelpPopover.propTypes = {
  children: PropTypes.node.isRequired,
};
