import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
// import supabase from "../config/supabaseClient";

import { createClient } from "@supabase/supabase-js";

// import environmental variables
const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseKey = import.meta.env.VITE_ANON_KEY;

// create a supabase client
const supabase = createClient(supabaseUrl, supabaseKey);

// Magic Link Example:
// https://begengpviegscbsydkds.supabase.co/auth/v1/verify?token=c57cf145e588f8d5f4cb507b3d4fdd80ee969816450785765bfbb52c&type=magiclink&redirect_to=http://localhost:3000

// Signup Email Confirmation Example - clicking on URL takes user to:
// https://begengpviegscbsydkds.supabase.co/auth/v1/verify?token=pkce_ef3071bdd0530c935941928ae9d69b1a03e1df5099bd272cf162e5bb&type=signup&redirect_to=http://localhost:3001/auth/callback
// https://begengpviegscbsydkds.supabase.co/auth/v1/verify?token=pkce_5bf5cd92f62df1379154a9b9de42ea07ee3bd3d380944db4e6895ce3&type=signup&redirect_to=http://localhost:5173/auth/callback

// * confirmation url when using 'createClient' in nextJS @supabase/supabase-js
// * looks like non-pkce flow
// https://begengpviegscbsydkds.supabase.co/auth/v1/verify?token=59c80fb7fa6782422891dfa0b72af2ced8fc3b136a825ce42ee3ecc1&type=signup&redirect_to=http://localhost:5173/auth/callback

//The redirect url then has a code=XXXX appended to it:
// http://localhost:5173/auth/callback?code=1c73b4e7-d37e-4777-94d7-5a7043801e22

// * This page needs to extract the code from the URL and then exchange it for an access token

export default function AuthCallback() {
  const [searchParams, setSearchParams] = useSearchParams();
  const code = searchParams.get("code");

  console.log("code", code);

  const exchangeCodeForAccessToken = async (code) => {
    try {
      const { data, error } = await supabase.auth.exchangeCodeForSession(code);

      if (error) {
        console.log("error", error);
        return;
      }
      console.log("data", data);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    if (code) {
      console.log("code:", code);
      exchangeCodeForAccessToken(code);
    }
  }, [code]);

  return <div>{code}</div>;
}
