import { Image } from "@mantine/core";
import { Alert, Button, Stack, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function ObrNotCompletedMsg({ entity_id }) {
  const navigate = useNavigate();
  return (
    <Alert severity="info" icon={false}>
      <Stack direction={"row"} spacing={3}>
        <Image src={"/header/startup.svg"} width={150} height={150} />
        <Stack spacing={2} p={2}>
          <Typography variant="h6">Please continue with your company setup</Typography>
          <Typography>Once your company is setup and processed, you will be able to view this section.</Typography>
          <Button sx={{ my: 0, width: "fit-content" }} variant="contained" size="medium" onClick={() => navigate(`/setup/entity/${entity_id}`)}>
            Continue
          </Button>
        </Stack>
      </Stack>
    </Alert>
  );
}
