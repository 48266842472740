import { useEffect, useState } from "react";

import { HelpPopover } from "../components/HelpPopover";
// import { useAuth } from "../contexts/useAuth";;
import { useAuth } from "../contexts/useAuth";
import { useParams } from "react-router-dom";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import supabase from "../config/supabaseClient";

// import useSelectDataByEntity from "../hooks/useSelectDataByEntity";

let nextStep = 0;

export default function Step101({ activeStep, setActiveStep, handleNext, handleBack }) {
  let { eid } = useParams();
  const { user } = useAuth();
  // [data] = useSelectDataByEntity("entity", eid);

  const [entityData, setEntityData] = useState({});

  const handleClickNext = async (event) => {
    event.preventDefault();

    // FIXME: validate step before this

    // Step specific logic here
    alert("Missing step specific logic here");

    // Advance to next step -> payment
  };

  const handleClickBack = () => {
    handleBack();
  };

  useEffect(() => {
    // Get Entity Data
    const getEntityData = async () => {
      const { data, error } = await supabase.from("entity").select("*").eq("id", eid).select();

      if (error) {
        console.log(error);
        return;
      }

      if (data.length > 0) {
        console.log("getEntityData data", data);
        // formik.setValues(data[0]);
        setEntityData(data[0]);
        // setActiveStep(data.last_step);
      }
    };
    getEntityData();
  }, [eid]);

  // useEffect(() => {
  //   // Get Entity Data
  // }, [eid]);

  return (
    <>
      <Stack spacing={2} mb={3}>
        <Alert severity="warning">
          <AlertTitle>Important</AlertTitle>
          You will need to use another name and order another NUANS Name Reservation Report if the proposed name does not meet all the necessary
          requirements and is rejected.
        </Alert>
        <Typography variant="body1">
          If you want to proceed with the name that you have proposed, click the “Proceed to Pay” button below and you will be taken to a payments
          page before continuing.
        </Typography>
        <Typography variant="body1">We will contact you as soon as the NUANS Name Reservation Report is generated.</Typography>
        <Stack direction="row" spacing={2} alignItems={"flex-start"}>
          <Typography variant="body1">
            After you receive the NUANS Name Reservation Report, please review it carefully and make sure the name that you propose to use meets all
            the necessary requirements.
          </Typography>
          <HelpPopover>
            <Stack spacing={2} p={5} maxWidth={300}>
              <Typography variant="h6">Requirements</Typography>
              <Divider />
              <Typography variant="body1">There are some of the requirements.....</Typography>
            </Stack>
          </HelpPopover>
        </Stack>
      </Stack>
      <Stack direction={"column"} spacing={2} alignItems={{ xs: "flex-start", sm: "flex-start", lg: "flex-start" }} mb={3}>
        <Typography variant="h6">Summary of NUANS Report Request</Typography>

        <Card variant="outlined" sx={{ width: "100%", alignSelf: "center" }}>
          <CardContent>
            <Grid container spacing={2} alignItems={"center"}>
              <Grid item>
                <Typography variant="h6">Proposed Name:</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body1">{entityData.english_name}</Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems={"center"}>
              <Grid item>
                <Typography variant="h6">Legal Name Ending:</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body1">{entityData.name_legal_element_ending}</Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Stack>
      <Stack direction="row" spacing={2} justifyContent={"space-between"}>
        <Button variant="contained" color="primary" onClick={handleClickBack}>
          Back
        </Button>
        <Button variant="contained" color="primary" onClick={() => alert("payment screen")}>
          Proceed to Payment
        </Button>
      </Stack>

      {/* <pre>{JSON.stringify(entityData, null, 2)}</pre> */}
    </>
  );
}
