import { Email } from "@mui/icons-material";
import { Button, Card, CardContent, CardHeader, Container, Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { useNavigate, useSearchParams } from "react-router-dom";

export default function MagicLinkNextSteps() {
  //Get the url parameters
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");

  const navigate = useNavigate();

  useEffect(() => {
    toast.success("Magic login link sent!");
  }, []);

  return (
    <Container
      maxWidth={100}
      sx={{ height: "100vh", width: "100vw", display: "flex", justifyContent: "center", alignItems: "center", bgcolor: "gray" }}
    >
      <Stack>
        <Card sx={{ p: 2, maxWidth: "500px", margin: "auto" }}>
          {/* <CardHeader title="Check your email" /> */}
          <CardContent>
            <Stack spacing={2} marginBottom={3} alignItems="center">
              <Email sx={{ fontSize: 80, color: "primary.main" }} />
              <Typography variant="h4">Check your email</Typography>
              <Typography variant="body1">
                We emailed a magic link to <strong>{email}</strong>.
              </Typography>
              <Typography variant="body1">Please click the link in the email to automatically login.</Typography>
            </Stack>
            <Stack spacing={2} direction="row" justifyContent="center">
              <Button variant="contained" color="primary" fullWidth onClick={() => navigate("/login")}>
                Return to login
              </Button>
            </Stack>
          </CardContent>
        </Card>
      </Stack>
    </Container>
  );
}
