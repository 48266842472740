import { Close, Email, Help, HelpOutline, Lock, Phone } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Card,
  CardContent,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  SpeedDial,
  SpeedDialAction,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { FormikProvider, useFormik } from "formik";
import { useEffect, useState } from "react";
import * as yup from "yup";
import { useAuth } from "../../contexts/auth";
import { createRequest, sendQuestionRequest } from "./requests";
import toast from "react-hot-toast";

// import Email from "../../../react-email";

//formik schema
const schema = yup.object().shape({
  name: yup.string().required("Name is required"),
  email: yup.string().email("Invalid email").required("Email is required"),
  phone: yup.string().required("Phone is required"),
  requestType: yup.string().required("Please select a request type"),
  message: yup.string().required("Please describe your question or request"),
});

const requestTypes = [
  { name: "General Question", value: "general_question" },
  { name: "Support Request", value: "support_request" },
  { name: "Request a shareholder additional or removal", value: "legal_shareholder_change" },
  { name: "Request a director addition or removal", value: "legal_director_change" },
  { name: "Request a change to the company's registered address", value: "legal_registered_address_change" },
  { name: "Request a change to the company's financial year end", value: "legal_financial_year_end_change" },
];

export default function HelpRequestPopup({ entityData }) {
  const { user } = useAuth();
  const [loadingSendQuestionRequest, setLoadingSendQuestionRequest] = useState(false);

  const [openSpeakWithUs, setOpenSpeakWithUs] = useState(false);
  const handleOpenSpeakWithUs = () => setOpenSpeakWithUs(true);
  const handleCloseSpeakWithUs = () => {
    formik.resetForm();
    setOpenSpeakWithUs(false);
  };

  const [openSubmitQuestionRequest, setOpenSubmitQuestionRequest] = useState(false);
  const handleOpenSubmitQuestionRequest = () => setOpenSubmitQuestionRequest(true);
  const handleCloseSubmitQuestionRequest = () => {
    formik.resetForm();
    setOpenSubmitQuestionRequest(false);
  };

  const actions = [
    { icon: <HelpOutline />, name: "I have a question or need help", onClick: handleOpenSubmitQuestionRequest },
    { icon: <Phone />, name: "Speak with our team", onClick: handleOpenSpeakWithUs },
  ];

  const formik = useFormik({
    initialValues: {
      entity_id: entityData.id,
      name: `${user?.user_metadata?.name_first} ${user?.user_metadata?.name_last}` || "",
      email: user?.email || "",
      phone: user?.user_metadata?.phone || "",
      requestType: "",
      message: "",
    },
    validationSchema: schema,
    onSubmit: handleSendQuestionRequest,
    validateOnChange: false,
    validateOnBlur: false,
  });

  async function handleSendQuestionRequest() {
    //setLoading
    setLoadingSendQuestionRequest(true);
    // make API request to send email to support
    // write the data to a requests table in the database
    // set loading to false
    // close modal
    try {
      await createRequest(formik.values);
      handleCloseSubmitQuestionRequest();
      toast.success("Your request has been sent. We will get back to you soon.");
    } catch (error) {
      console.error("Error sending question request:", error);
      toast.error("An error occurred while sending your request. Please try again later.");
    } finally {
      formik.resetForm();
      setLoadingSendQuestionRequest(false);
    }
  }

  // useEffect(() => {
  //   // set the initial values of the form
  //   if (user) {
  //     formik.
  //   }
  // }, [user]);

  return (
    <>
      <SpeedDial ariaLabel="Help popup button" sx={{ position: "absolute", bottom: 16, right: 16 }} icon={<Help />}>
        {actions.map((action) => (
          <SpeedDialAction key={action.name} icon={action.icon} tooltipTitle={action.name} onClick={action.onClick} />
        ))}
      </SpeedDial>

      {/* Modal: Speak with us */}
      <Modal open={openSpeakWithUs} onClose={handleCloseSpeakWithUs} disableEscapeKeyDown>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            minWidth: "390px",
            maxWidth: "800px",
            bgcolor: "background.paper",

            boxShadow: 24,
            p: 4,
          }}
        >
          <Stack direction="column" spacing={2} display={"flex"} justifyContent={"start"}>
            <Typography variant="h6">Speak with our team</Typography>
            <Typography variant="body1">Our team is here to help you with any questions or concerns you may have.</Typography>
            <Card variant="outlined" sx={{ minWidth: "300px", bgcolor: "card.main" }} p={3}>
              <CardContent>
                <Stack direction="column" spacing={2}>
                  <Stack direction="row" spacing={2} alignItems={"center"}>
                    <Phone />
                    <Typography variant="h6" component={"a"} href="tel:4165555555">
                      416.555.5555
                    </Typography>
                  </Stack>
                  <Stack direction="row" spacing={2} alignItems={"center"}>
                    <Email />
                    <Typography variant="body1" component={"a"} href="mailto:hello@skylaunch.ca">
                      hello@skylaunch.ca
                    </Typography>
                  </Stack>
                  <Stack direction="row" spacing={2} alignItems={"center"}>
                    <Lock />
                    <Typography variant="body1">Secure code: {entityData.customer_ref}</Typography>
                  </Stack>
                </Stack>
              </CardContent>
            </Card>
          </Stack>
        </Box>
      </Modal>

      {/* Modal: Submit question or request */}
      <Modal open={openSubmitQuestionRequest} onClose={handleCloseSubmitQuestionRequest} disableEscapeKeyDown>
        <FormikProvider value={formik}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              minWidth: "320px",
              maxWidth: "800px",
              bgcolor: "background.paper",

              boxShadow: 24,
              p: 3,
            }}
          >
            <Stack direction="column" spacing={2} display={"flex"} justifyContent={"start"}>
              <Stack direction="row" spacing={2} display={"flex"} justifyContent={"space-between"} mb={3}>
                <Typography variant="h6">Have a question or request?</Typography>
                <IconButton onClick={handleCloseSubmitQuestionRequest}>
                  <Close />
                </IconButton>
              </Stack>
              <Typography variant="body1">Let us know how we can help you.</Typography>

              <Stack direction="column" spacing={2}>
                <TextField
                  label="Name"
                  variant="outlined"
                  {...formik.getFieldProps("name")}
                  error={!!formik.errors.name}
                  helperText={formik.errors.name}
                />
                <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
                  <TextField
                    label="Email"
                    variant="outlined"
                    fullWidth
                    {...formik.getFieldProps("email")}
                    error={!!formik.errors.email}
                    helperText={formik.errors.email}
                  />
                  <TextField label="Phone" variant="outlined" fullWidth {...formik.getFieldProps("phone")} error={!!formik.errors.phone} />
                </Stack>

                <FormControl>
                  <InputLabel id="request-type-label">Request Type</InputLabel>
                  <Select
                    name="request-type"
                    labelId="request-type-label"
                    id="request-type"
                    label="Request Type"
                    {...formik.getFieldProps("requestType")}
                    error={!!formik.errors.requestType}
                    helperText={formik.errors.requestType}
                  >
                    {requestTypes.map((requestType) => (
                      <MenuItem key={requestType.value} value={requestType.value} sx={{ fontSize: "14px" }}>
                        {requestType.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <TextField
                  id="outlined-basic"
                  label="Briefly describe your question or request"
                  variant="outlined"
                  multiline
                  rows={4}
                  {...formik.getFieldProps("message")}
                  error={!!formik.errors.message}
                  helperText={formik.errors.message}
                />
                <LoadingButton
                  variant="contained"
                  onClick={formik.handleSubmit}
                  loading={loadingSendQuestionRequest}
                  disabled={loadingSendQuestionRequest}
                >
                  Send
                </LoadingButton>
              </Stack>
            </Stack>
            {/* <pre>{JSON.stringify(user, null, 2)}</pre> */}
          </Box>
        </FormikProvider>
      </Modal>
    </>
  );
}
