import { Stack, Typography } from "@mui/material";

import PropTypes from "prop-types";
import { Children } from "react";

const StepHeader = ({ children, title, subtitle, img_src, helpcomponent }) => {
  return (
    <>
      {/* Step header */}
      <Stack direction="row" spacing={3} justifyContent={"left"} bgcolor={"card.lightblue"} sx={{ mb: 2, py: 1, px: 2, borderRadius: 1 }}>
        <img src={img_src} alt="location" width={200} height={200} />
        <Stack direction="column" spacing={2} margin={3} p={4}>
          <Typography variant="h5">{title}</Typography>
          <Stack direction="row" spacing={2} alignItems={"center"}>
            <Typography variant="subtitle1">{subtitle}</Typography>
            {children}
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

StepHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  img_src: PropTypes.string.isRequired,
  helpcomponent: PropTypes.element,
  children: PropTypes.element,
};

export default StepHeader;
