import { Button, Card, CardContent, CardHeader, Container, Stack, Typography } from "@mui/material";

export default function ConfirmSignup() {
  //Get the url parameters
  const params = new URLSearchParams(window.location.search);
  const confirmation_url = decodeURI(params.get("confirmation_url"));

  function handleConfirm() {
    // alert(confirmation_url);
    console.log("confirmation_url: ", confirmation_url);
    window.location.href = confirmation_url;

    // redirect user to external url: confirmation_url
  }

  return (
    <Container maxWidth="md">
      <Stack>
        <Card sx={{ p: 2, maxWidth: "500px", margin: "auto" }}>
          <CardHeader title="Confirm Your Account" />
          <CardContent>
            <Stack spacing={2} marginBottom={3}>
              <Typography variant="body1">Please confirm your account and continue setting up your company!</Typography>
            </Stack>
            <Stack spacing={2} direction="row" justifyContent="center">
              <Button variant="contained" color="primary" fullWidth onClick={handleConfirm}>
                Confirm
              </Button>
            </Stack>
          </CardContent>
        </Card>
      </Stack>
    </Container>
  );
}
