import React from "react";
import { Outlet } from "react-router-dom";
import NavBarEntityCreate from "../components/NavBarEntityCreate";
import { Box, Container, Stack } from "@mui/material";
import { ToastBar, Toaster } from "react-hot-toast";

function EntityCreateLayout({ children }) {
  return (
    <div>
      <NavBarEntityCreate />
      <Container maxWidth="lg">
        <Outlet />
        <Toaster />
      </Container>
    </div>
  );
}

export default EntityCreateLayout;
