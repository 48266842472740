import React, { useEffect, useState } from "react";
import supabase from "../../../config/supabaseClient";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Chip, CircularProgress, Divider, Fade, Stack, Typography } from "@mui/material";
import LoadingSkeleton from "../../LoadingSkeleton";

export default function ShareRegisterTable({ entityId }) {
  const [shareRegisterData, setShareRegisterData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // console.log("entityId", entityId);
    const getShareOwnershipData = async () => {
      setLoading(true);
      const { data: shareOwnershipData, error: shareOwnershipError } = await supabase
        .from("share_ownership")
        .select("*")
        .eq("entity_id", entityId)
        .order("created_at", { ascending: false })
        .limit(1)
        .single();

      if (shareOwnershipError) console.log("shareOwnershipError", shareOwnershipError);
      if (shareOwnershipData) setShareRegisterData(shareOwnershipData);
      setLoading(false);
    };
    getShareOwnershipData();

    //cleanup
    return () => {
      setShareRegisterData({});
    };
  }, [entityId]);

  if (loading)
    return (
      <Fade in={true} timeout={500} transitionDelay={5000}>
        <Box sx={{ height: "100px", width: "100%" }}>
          {/* <CircularProgress /> */}
          <LoadingSkeleton />
        </Box>
      </Fade>
    );
  return (
    <Fade in={true} timeout={1000} transitionDelay={1000}>
      <Stack>
        {/* <pre>{JSON.stringify(shareRegisterData, null, 2)}</pre> */}
        {shareRegisterData.share_ownership_snapshot_json && shareRegisterData.share_ownership_snapshot_json.length > 0 ? (
          <TableContainer sx={{ bgcolor: "card.main", border: 1, borderColor: "divider" }}>
            <Table stickyHeader aria-label="share register table" fullWidth>
              <TableHead>
                <TableRow>
                  <TableCell>Shareholder</TableCell>
                  <TableCell>
                    <Stack direction="row" justifyContent={"space-between"} spacing={2}>
                      <Typography>Class</Typography>
                      <Typography>Qty</Typography>
                    </Stack>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {shareRegisterData.share_ownership_snapshot_json
                  .sort((a, b) => a.shareholder_fullname.localeCompare(b.shareholder_fullname))
                  .map((shareholder, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <Stack spacing={2}>
                          <Typography sx={{ fontWeight: "bold" }}>{shareholder.shareholder_fullname}</Typography>
                          <Typography>{shareholder.shareholder_address}</Typography>
                        </Stack>
                      </TableCell>
                      <TableCell>
                        <Stack spacing={2} justifyItems={"center"}>
                          {shareholder.shares.map((share, index) => (
                            <Stack
                              direction="row"
                              spacing={2}
                              justifyContent={"space-between"}
                              key={index}
                              pb={1}
                              display={share.qty > 0 ? "flex" : "none"}
                            >
                              <Chip label={share.share_class_name} variant="filled" sx={{ bgcolor: "lightgray" }} />
                              <Typography sx={{ fontWeight: "bold" }}>{share.qty.toLocaleString()}</Typography>
                            </Stack>
                          ))}
                        </Stack>
                      </TableCell>
                      {/* <TableCell>{shareholder.shares[0].share_class_name}</TableCell> */}
                      {/* <TableCell>{shareholder.shares[0].qty}</TableCell> */}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography variant="h6">No share register data found</Typography>
        )}
      </Stack>
    </Fade>
  );
}
