// Audit utilities
import PropTypes from "prop-types";
import supabase from "../config/supabaseClient";
import toast from "react-hot-toast";

export async function addAuditTrailEntry({ entity_id, type, app, app_context, user_id, description }) {
  const { data, error } = await supabase.from("audit_trail").insert({
    entity_id: entity_id,
    type: type,
    app: app,
    app_context: app_context,
    performed_by: user_id,
    description: description,
  });
  if (error) {
    // toast.error("Error creating audit trail entry: " + error.message);
    console.log("error creating audit trail entry: ", error);
  } else {
    // toast.success("Audit trail entry created");
    console.log("audit trail entry created: ", data);
  }
}

// define prop types for the function
addAuditTrailEntry.propTypes = {
  entity_id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  app: PropTypes.string.isRequired,
  app_context: PropTypes.string.isRequired,
  user_id: PropTypes.string.isRequired,
  performed_by: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};
