import axios from "axios";
import PropTypes from "prop-types";
import supabase from "../../config/supabaseClient";
import * as yup from "yup";

export async function sendQuestionRequest(data) {
  // make API request to send email to support
  // write the data to a requests table in the database

  const xAPIKey = "ca5586a0-888a-4917-86af-a9eb7e35d160";

  // const url = `${import.meta.env.VITE_STAFF_PORTAL_URL || "http://localhost:3000"}/api/requests/`;
  const url = `http://localhost:3000/api/requests/`;

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "x-api-key": xAPIKey,
        "Content-Type": "application/json",
      },
      redirect: "follow",
      body: JSON.stringify(data),
    });
    return response.json();
  } catch (error) {
    console.error("Error sending question request:", error);
    throw error;
  }
}

export async function createRequest(data) {
  // write the data to a requests table in the database
  // make API request to send email to support

  // validate the data
  const schema = yup.object().shape({
    entity_id: yup.string().required("Entity ID is required"),
    name: yup.string().required("Name is required"),
    email: yup.string().email("Invalid email").required("Email is required"),
    phone: yup.string().required("Phone is required"),
    requestType: yup.string().required("Request type is required"),
    message: yup.string().required("Message is required"),
  });

  const { error: validationError } = await schema.validate(data);
  console.log("validationError", validationError);
  if (validationError) {
    throw validationError;
  }

  const { data: createdData, error: createdError } = await supabase
    .from("request")
    .insert({
      entity_id: data.entity_id,
      requestor_fullname: data.name,
      requestor_email: data.email,
      requestor_phone: data.phone,
      type: data.requestType,
      description: data.message,
    })
    .select();

  if (createdError) {
    throw createdError;
  }

  return { createdData, createdError };
}
