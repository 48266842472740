import { useRouteError } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <Stack spacing={3} p={10}>
      <img src="/skylaunch_logo.svg" width={"300px"} />
      <Typography variant="h5">Opps! We ran into a problem</Typography>

      <Alert severity="info">
        <i>{error.statusText || error.message}</i>
      </Alert>
      <Stack spacing={2} direction="row">
        <Button variant="contained" color="primary" href="/dashboard">
          Return to Dashboard
        </Button>
      </Stack>
    </Stack>
  );
}
