import { Box, Button, Card, Container, Stack, Typography } from "@mui/material";
import { Delete } from "@mui/icons-material";
// import Delete from "@mui/icons-material/Delete";

import React, { useEffect, useState } from "react";
// import ConfettiExplosion from "react-confetti-explosion";
import { useNavigate } from "react-router-dom";
import Fireworks from "react-canvas-confetti/dist/presets/fireworks";
// import Realistic from "react-canvas-confetti/dist/presets/realistic";
import Lottie from "lottie-react";
import lottieFile from "./lottie_skylaunchIcon.json";

export default function FinishedPaid() {
  //enhanced fireworks
  const [conductor, setConductor] = useState();
  const [showFireworks, setShowFireworks] = useState(true);

  const onOnce = () => {
    conductor?.shoot();
  };
  const onRun = () => {
    conductor?.run({ speed: 1 });
  };
  const onPause = () => {
    conductor?.pause();
  };
  const onStop = () => {
    conductor?.stop();
  };

  const onInit = ({ conductor }) => {
    setConductor(conductor);
  };

  useEffect(() => {
    if (conductor) {
      conductor?.run({ speed: 1 });
    }
  }),
    [conductor];

  const [showConfetti, setShowConfetti] = React.useState(false);

  const navigate = useNavigate();

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setShowConfetti(true);
  //     console.log("set true");
  //     setTimeout(() => {
  //       setShowConfetti(false);
  //       console.log("set false");
  //     }, 1000);
  //   }, 2000);

  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, []);

  const handleReturnToDashboard = () => {
    navigate("/dashboard");
  };

  const StyleLottie = {
    height: "100px",
    padding: "10px",
  };

  return (
    <Container>
      <Stack direction="column" spacing={2} alignItems="center" justifyContent={"center"} mt={10}>
        {/* <ConfettiExplosion /> */}
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          {/* {showConfetti && <ConfettiExplosion />} */}
          <Stack spacing={2} alignItems="center" justifyContent={"center"}>
            <Card sx={{ p: 2, minWidth: "320px", margin: "auto" }}>
              <Stack justifyContent={"center"} alignItems={"center"} display={"flex"}>
                <Lottie animationData={lottieFile} style={StyleLottie} />
              </Stack>
              <Typography variant="h4" align="center">
                Congratulations!
              </Typography>
              <Typography variant="body1" align="center" marginTop={3} marginBottom={8}>
                We received your request and are working on it. Stay tuned and we&apos;ll be in touch!
              </Typography>
              <Button variant="contained" color="primary" fullWidth onClick={handleReturnToDashboard}>
                Return to Dashboard
              </Button>
            </Card>
          </Stack>
        </Box>
        <Fireworks autorun={{ speed: 2 }} />

        {/* <Realistic onInit={onInit} style={{ position: "absolute", top: "5px" }} /> */}
      </Stack>
    </Container>
  );
}
