import styled from "@emotion/styled";
import { Notifications } from "@mui/icons-material";
import { AppBar, Avatar, Badge, Box, Button, IconButton, Menu, MenuItem, Toolbar } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useState } from "react";
import { useAuth } from "../contexts/useAuth";
import { useNavigate, useParams } from "react-router-dom";

const StyledToolbar = styled(Toolbar)({
  display: "flex",
  justifyContent: "space-between",
});

const Icons = styled(Box)(({ theme }) => ({
  display: "none",
  alignItems: "center",
  gap: "20px",
  [theme.breakpoints.up("sm")]: {
    display: "flex",
  },
}));

const UserBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "20px",
  [theme.breakpoints.up("sm")]: {
    display: "none",
  },
}));

export default function NavBarEntityCreate() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const { user, signOut } = useAuth();
  const navigate = useNavigate();

  //  get eid from url
  const { eid } = useParams();
  console.log("eid", eid);

  function handleClick(event) {
    setOpen(true);
    setAnchorEl(event.currentTarget);
  }

  function handleClickExit() {
    // clear localstorage
    localStorage.removeItem("currentStep");
    localStorage.removeItem("isNumbered");
    localStorage.removeItem("reqCompanyName");
    localStorage.removeItem("hasNuans");
    // navigate(`/view/${eid}`);
    navigate("/dashboard");
  }

  return (
    <AppBar position="sticky" sx={{ bgcolor: "white", borderRadius: 5, boxShadow: "none", width: "100%" }}>
      <StyledToolbar sx={{ justifyContent: "space-between" }}>
        {/* <IconButton color="primary" aria-label="open drawer" edge="start" onClick={handleDrawerToggle} sx={{ mr: 2, display: { sm: "none" } }}>
          <MenuIcon />
        </IconButton> */}
        <img src="/skylaunch_icon.svg" alt="Sky Launch Logo" width="50px" height="50px" onClick={() => navigate("/dashboard")} />
        {/* <Button variant="contained" color="primary" onClick={handleSignOut}>
            Logout
          </Button> */}

        {/* <Icons>
          <Badge badgeContent={4} color="secondary">
            <Notifications color="primary" />
          </Badge>
          <Avatar id="avatar" onClick={handleClick}>
            {user?.user_metadata?.name_first[0] + user?.user_metadata?.name_last[0]}
          </Avatar>
        </Icons>
        <UserBox>
          <Avatar id="avatar" onClick={handleClick}>
            {user?.user_metadata?.name_first[0]}
          </Avatar>
        </UserBox> */}
        <Button variant="contained" color="primary" onClick={handleClickExit}>
          Exit
        </Button>
      </StyledToolbar>
      {/* positioned menu */}
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        open={open}
        onClose={() => setOpen(false)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{ mt: 5 }}
      >
        <MenuItem onClick={() => navigate("/profile")}>Profile</MenuItem>
        <MenuItem onClick={() => navigate("/billing")}>Billing</MenuItem>
        <MenuItem onClick={() => signOut()}>Logout</MenuItem>
      </Menu>
    </AppBar>
  );
}
