import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.jsx";

import "./App.css";
import "@progress/kendo-theme-default/dist/all.css";

import * as Sentry from "@sentry/react";

Sentry.init({
  environment: import.meta.env.VITE_APP_MODE === "production" ? "production" : "development",
  dsn: "https://5740f14fe6734962e21954f1fe328e8a@o4506681254871040.ingest.us.sentry.io/4507296793427968",
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.createRoot(document.getElementById("root")).render(
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
);
