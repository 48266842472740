import supabase from "../../../config/supabaseClient";

export async function getShareLedgerByEntityId(entity_id) {
  const { data: shareTransactionData, error: shareTransactionError } = await supabase
    .from("share_transaction")
    // .select('*')
    .select(
      `
      created_at,
      entity_id,
      entity_name:entity(english_name),
      to_shareholder_id,
      from_shareholder_id,
      share_class_id,
      qty,
      from_treasury,
      to_treasury,
      transaction_date,
      transaction_type,
      to_shareholder:shareholder!share_transaction_to_shareholder_id_fkey(id, first_name, last_name, street_name, street_address, unit, jurisdiction, city, postal_code, is_canadian_resident, is_corporation, corporation_name, corporation_number, country),
      from_shareholder:shareholder!share_transaction_from_shareholder_id_fkey(id, first_name, last_name, street_name, street_address, unit, jurisdiction, city, postal_code, is_canadian_resident, is_corporation, corporation_name, corporation_number, country),
      share_class:share_class(id, name)
    `
    )
    .eq("entity_id", entity_id)
    .order("transaction_date", { ascending: true });

  if (shareTransactionError) {
    throw new Error(`Error fetching share transactions: ${shareTransactionError}`);
  }

  const groupedData = shareTransactionData.reduce((result, transaction) => {
    // Get share class ID as string
    // const shareClassId = transaction.share_class_id ? transaction.share_class_id.toString() : '';
    const shareClassId = transaction.share_class?.name ? transaction.share_class?.name.toString() : "";

    // Combine to_shareholder_id and from_shareholder_id as the second group key
    // const toId = transaction.to_shareholder_id ? transaction.to_shareholder_id.toString() : 'null';
    const toId = transaction.to_shareholder ? transaction.to_shareholder.first_name + " " + transaction.to_shareholder.last_name : "null";
    const fromId = transaction.from_shareholder ? transaction.from_shareholder.first_name + " " + transaction.from_shareholder.last_name : "null";

    // Ensure both to_shareholder_id and from_shareholder_id appear together
    const combinedIds = new Set([toId, fromId].filter((id) => id !== "null"));

    // Initialize groupings if they don't exist
    if (!result[shareClassId]) {
      result[shareClassId] = {};
    }

    // Group transactions for all shareholders in the set
    combinedIds.forEach((id) => {
      if (!result[shareClassId][id]) {
        result[shareClassId][id] = [];
      }
      result[shareClassId][id].push(transaction);
    });

    // sort the transactions by created_at date
    Object.keys(result[shareClassId]).forEach((id) => {
      result[shareClassId][id].sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());
    });

    // sort by share class name alphabetically
    // Object.keys(result[shareClassId]).sort().forEach((id) => {
    //   result[shareClassId][id].sort((a: any, b: any) => a.share_class.name.localeCompare(b.share_class.name));
    // });

    return result;
  }, {});

  console.log(groupedData);

  return {
    groupedData,
    date_generated: new Date().toLocaleDateString("en-US", { year: "numeric", month: "long", day: "numeric", hour: "2-digit", minute: "2-digit" }),
    entity: shareTransactionData[0].entity_name,
  };
  // return { distinctShareClasses, distinctShareHolders_to, distinctShareHolders_from, groupedByShareholder_to, groupedByShareholder_from, groupedShareTransactionData };
}

// create a new ownership snapshot and save it to the database
export async function createOwnershipSnapshot(entity_id) {
  console.log("createOwnershipSnapshot", entity_id);

  // compute the share ownership
  const ownershipSnapshot = await computeShareOwnership(entity_id);

  // Insert a new ownership snapshot
  const { data: newSnapshot, error: insertError } = await supabase.from("share_ownership").insert([
    {
      entity_id: entity_id,
      share_ownership_snapshot_json: ownershipSnapshot,
    },
  ]);

  if (insertError) {
    throw new Error(`Error inserting share ownership snapshot: ${insertError.message}`);
  }

  return newSnapshot;
}

// compute the share ownership - only used for creating a new ownership snapshot
async function computeShareOwnership(entity_id) {
  // Fetch transactions and join with Shareholder and ShareClass using explicit relationships
  const { data: transactions, error } = await supabase
    .from("share_transaction")
    .select(
      `
      to_shareholder_id,
      from_shareholder_id,
      share_class_id,
      qty,
      from_treasury,
      to_treasury,
      to_shareholder:shareholder!share_transaction_to_shareholder_id_fkey(id, first_name, last_name, street_name, street_address, unit, city, jurisdiction, postal_code, is_canadian_resident, is_corporation, corporation_name, corporation_number, country),
      from_shareholder:shareholder!share_transaction_from_shareholder_id_fkey(id, first_name, last_name, street_name, street_address, unit, city, jurisdiction, postal_code, is_canadian_resident, is_corporation, corporation_name, corporation_number, country),
      share_class:share_class(id, name)
    `
    )
    .eq("entity_id", entity_id);

  if (error) {
    throw new Error(`Error fetching transactions: ${error.message}`);
  }

  // const ownershipMap: Record<string, any> = {};
  const ownershipMap = {};
  transactions.forEach((transaction) => {
    const { to_shareholder_id, from_shareholder_id, share_class_id, qty, from_treasury, to_treasury, to_shareholder, from_shareholder, share_class } =
      transaction;

    // Construct the full name and IDs
    // handle the case where the shareholder is a corporation - shareholder.first_name will be the corporation name
    const toShareholderName = to_shareholder
      ? to_shareholder.is_corporation
        ? to_shareholder.corporation_name
        : `${to_shareholder.first_name} ${to_shareholder.last_name}`
      : "";
    const toShareholderAddress = to_shareholder
      ? `${to_shareholder.street_address} ${to_shareholder.unit}, ${to_shareholder.city}, ${to_shareholder.jurisdiction}, ${to_shareholder.postal_code} ${to_shareholder.country} `
      : "";

    const fromShareholderName = from_shareholder
      ? from_shareholder.is_corporation
        ? from_shareholder.corporation_name
        : `${from_shareholder.first_name} ${from_shareholder.last_name}`
      : "";
    const fromShareholderAddress = from_shareholder
      ? `${from_shareholder.street_address} ${from_shareholder.unit}, ${from_shareholder.city}, ${from_shareholder.jurisdiction}, ${from_shareholder.postal_code} ${from_shareholder.country} `
      : "";

    const shareClassName = share_class?.name || "";
    const shareClassId = share_class?.id || "";

    // Handle shares coming from treasury (new issuance)
    if (from_treasury && to_shareholder_id !== null && share_class_id !== null && qty !== null) {
      if (!ownershipMap[to_shareholder_id]) {
        ownershipMap[to_shareholder_id] = {
          shareholder_fullname: toShareholderName,
          shareholder_address: toShareholderAddress,
          shareholder_id: to_shareholder_id,
          shares: [],
        };
      }

      const existingClass = ownershipMap[to_shareholder_id].shares.find((share) => share.share_class_id === shareClassId);

      if (existingClass) {
        existingClass.qty += qty;
      } else {
        ownershipMap[to_shareholder_id].shares.push({
          share_class_name: shareClassName,
          share_class_id: shareClassId,
          qty,
        });
      }
    }

    // Handle transfers from one shareholder to another
    if (from_shareholder_id && to_shareholder_id && !from_treasury && !to_treasury && share_class_id !== null && qty !== null) {
      // Subtract from the sender
      if (!ownershipMap[from_shareholder_id]) {
        ownershipMap[from_shareholder_id] = {
          shareholder_fullname: fromShareholderName,
          shareholder_address: fromShareholderAddress,
          shareholder_id: from_shareholder_id,
          shares: [],
        };
      }

      const senderClass = ownershipMap[from_shareholder_id].shares.find((share) => share.share_class_id === shareClassId);

      if (senderClass) {
        senderClass.qty -= qty;
      } else {
        ownershipMap[from_shareholder_id].shares.push({
          share_class_name: shareClassName,
          share_class_id: shareClassId,
          qty: -qty,
        });
      }

      // Add to the receiver
      if (!ownershipMap[to_shareholder_id]) {
        ownershipMap[to_shareholder_id] = {
          shareholder_fullname: toShareholderName,
          shareholder_address: toShareholderAddress,
          shareholder_id: to_shareholder_id,
          shares: [],
        };
      }

      const receiverClass = ownershipMap[to_shareholder_id].shares.find((share) => share.share_class_id === shareClassId);

      if (receiverClass) {
        receiverClass.qty += qty;
      } else {
        ownershipMap[to_shareholder_id].shares.push({
          share_class_name: shareClassName,
          share_class_id: shareClassId,
          qty,
        });
      }
    }
  });

  // Convert the ownershipMap into an array
  return Object.values(ownershipMap);
}
