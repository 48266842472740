import { useEffect } from "react";

import { HelpPopover } from "../components/HelpPopover";
// import { useAuth } from "../contexts/useAuth";;
import { useAuth } from "../contexts/useAuth";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import * as Yup from "yup";
import supabase from "../config/supabaseClient";
import { useFormik } from "formik";
import legalNameEndings from "../data/data_legal_endings.json";
// import useSelectDataByEntity from "../hooks/useSelectDataByEntity";

// Yup Validation Schema
const schemaEntity = Yup.object().shape({
  name: Yup.string().required("Required"),
  file_number: Yup.string(),
  status: Yup.string(),
  is_numbered: Yup.boolean(),
  legal_element_ending: Yup.string(),
  naics_code: Yup.string(),
  naics_description: Yup.string(),
  english_name: Yup.string().required("Required"),
  name_legal_element_ending: Yup.string().required("Required"),
  eng_nuans_ref_number: Yup.string(),
  eng_nuans_report_date: Yup.date(),
  min_num_directors: Yup.number(),
  max_num_directors: Yup.number(),
  req_inc_date: Yup.date(),
  official_business_email: Yup.string().email("Invalid email address"),
  is_req_nuans_report: Yup.boolean(),
  last_step: Yup.number(),
  ra_is_standard_address: Yup.boolean().required("Required"),
  ra_lot_address: Yup.string(),
  ra_street_address: Yup.string().required("Required"),
  ra_street_name: Yup.string().required("Required"),
  ra_unit: Yup.string().required("Required"),
  ra_city: Yup.string().required("Required"),
  ra_country: Yup.string().required("Required"),
  ra_jurisdiction: Yup.string().required("Required"),
  ra_postal_code: Yup.string().required("Required"),
});

let nextStep = 0;

// =========================================================
// Update Entity w/ activeStep
// =========================================================
async function updateEntity(eid, entityData, step) {
  entityData.last_step = step;
  console.log("from update function eid: ", eid);
  console.log("from update function updated step: ", entityData.last_step);
  console.log("from update function entityData: ", entityData);

  const { data, error } = await supabase.from("entity").update(entityData).eq("id", eid).select().single();

  if (error) {
    console.log("error", error);
  }

  if (data) {
    console.log("updateEntity Function Data result: ", data);
    return data;
  }
}

export default function Step100({ activeStep, setActiveStep, handleNext, handleBack }) {
  let { eid } = useParams();
  const { user } = useAuth();
  // [data] = useSelectDataByEntity("entity", eid);

  const formik = useFormik({
    initialValues: {
      name: "",
      english_name: "",
      legal_element_ending: "Ltd",
      ra_jurisdiction: "ON",
      ra_street_address: "",
      ra_street_name: "",
      ra_is_standard_address: true,
      ra_city: "",
      ra_postal_code: "",
      inc_is_sole_shareholder: true,
    },

    validationSchema: schemaEntity,
    // onSubmit: async (values) => {
    //   console.log("values", values);
    //   const { error } = await logIn(values);
    //   if (error) {
    //     alert(error.message);
    //   }
    //   // handleSignIn(values);
    // },
  });

  const handleClickNext = async (event) => {
    event.preventDefault();

    // FIXME: validate step before this

    // Step specific logic here
    if (formik.values.legal_element_ending === "" || null) {
      console.log("legal_element_ending is empty");
      return;
    } else if (formik.values.english_name === "" || null) {
      console.log("english_name is empty");
      return;
    } else {
      nextStep = 101; //the next step in NUANS request
    }

    // Update database with changes made in this step
    updateEntity(eid, formik.values, nextStep).then((data) => {
      console.log("Step1 returned data", data);
      // setEntityData(data);
      formik.setValues(data);
      // setActiveStep(data.last_step);
      handleNext();
    });
  };

  const handleClickBack = () => {
    handleBack(0);
  };

  useEffect(() => {
    // Get Entity Data
    const getEntityData = async () => {
      const { data, error } = await supabase.from("entity").select("*").eq("id", eid).select();

      if (error) {
        console.log(error);
        return;
      }

      if (data.length > 0) {
        console.log("getEntityData data", data);
        formik.setValues(data[0]);
        // setActiveStep(data.last_step);
      }
    };
    getEntityData();
  }, [eid]);

  // useEffect(() => {
  //   // Get Entity Data
  // }, [eid]);

  return (
    <>
      <Stack direction="column" spacing={3} justifyContent={"flex-start"}>
        <form onSubmit={formik.handleSubmit}>
          <Stack spacing={2} mb={3}>
            <Typography variant="h6">To get started, please tell us the proposed name of your company:</Typography>
          </Stack>
          <Stack
            direction={{ xs: "column", sm: "row", lg: "row" }}
            spacing={2}
            alignItems={{
              xs: "flex-start",
              sm: "flex-start",
              lg: "flex-start",
            }}
          >
            <FormControl fullWidth sx={{ maxWidth: "300px" }}>
              <TextField
                id="english_name"
                name="english_name"
                value={formik.values.english_name}
                error={formik.touched.english_name && Boolean(formik.errors.english_name)}
                label="Company Name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                helperText={formik.touched.english_name && formik.errors.english_name}
              />
            </FormControl>

            <FormControl sx={{ minWidth: 200 }}>
              <InputLabel id="legal-element-input-label">Legal Name Ending</InputLabel>
              <Select
                name="name_legal_element_ending"
                value={formik.values.name_legal_element_ending == null ? "Inc" : formik.values.name_legal_element_ending}
                label="Legal Name Ending"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.name_legal_element_ending && Boolean(formik.errors.name_legal_element_ending)}
              >
                {legalNameEndings.map((ending) => (
                  <MenuItem key={ending.id} value={ending.name}>
                    {ending.name}
                  </MenuItem>
                ))}
              </Select>
              {formik.touched.name_legal_element_ending && formik.errors.name_legal_element_ending && (
                <FormHelperText error>{formik.errors.name_legal_element_ending}</FormHelperText>
              )}
            </FormControl>
          </Stack>
          <Card
            variant="outlined"
            sx={{
              my: 3,
              display: formik.values.english_name ? "flex" : "none",
            }}
          >
            <CardContent sx={{ p: 3 }}>
              <Stack spacing={2} direction={"row"}>
                <Typography variant="h6">Results of pre-search for {formik.values.english_name} ...</Typography>
              </Stack>
            </CardContent>
          </Card>

          <Stack direction="row" spacing={2} justifyContent={"space-between"} mt={3}>
            <Button variant="contained" color="primary" onClick={handleClickBack}>
              Back
            </Button>
            <Button variant="contained" color="primary" onClick={handleClickNext}>
              Next
            </Button>
          </Stack>
        </form>

        <pre>{JSON.stringify(formik.values, null, 2)}</pre>
      </Stack>
    </>
  );
}
